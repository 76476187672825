import React, { Component } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import PaymentModifierContainer from 'aligentreact/build/containers/PaymentModifierContainer/PaymentModifierContainer';
import { deepEqual } from 'fast-equals';
import debounce from 'debounce-promise';
import get from 'lodash.get';
import AJAX from 'aligentreact/build/utils/AJAX';
import Storage from '../Util/Storage';
import HeaderTabs from './containers/HeaderTabs';
import StaticBlock from './containers/StaticBlock';
import ItemSummary from './containers/ItemSummary';
import Delivery from './containers/Delivery';
import Payment from './containers/Payment';
import Gtm from './containers/Gtm';
import ErrorContainer from "./containers/ErrorContainer";
import {
    addressFormat,
    addressLookup,
    addressValidate,
    applyCouponCode,
    removeCouponCode,
    fetchPaymentInformation,
    applyCard,
    removeCard,
    updateProduct,
    suburbLookup,
    estimateShippingMethods,
    submitCheckout,
    removeItem,
    setShippingInformation,
    setBillingAddress,
    setPaymentInformation,
    setClickAndCollectStore,
    validateQuote,
    removeClickAndCollectStore
} from '../Util/API';
import {
    processBillingAddressForCheckout,
    processStoreAddressForCheckout,
    isMobileUi,
    extractConfigurable,
    buildShippingOptionsArray,
    parseDefaultAddress,
    featureFlags,
    isValidEmail,
    isValidPostcode,
    isValidTelephone,
    getProductOption, //#gta4
    getGoogleAnalytics4, //#gta4
} from '../Util/functions';
import { getSuburbLabelForCountry, getRegionLabelForCountry } from './utils';
import SuccessContainer from "./containers/SuccessContainer";
import { validateNameFields } from './utils/validate-name-fields';
const emailRegex = /(.+)@(.+)\.(.+){2,}/;
const telephoneRegex = /^[\d]{1,15}$/;

const INVALID_ADDRESS_ERROR_MESSAGE = 'The address you entered doesn\'t look correct. Please check it to ensure it\'s correct.';

class App extends Component {

    constructor(props) {
        super(props);
        this.paymentComponentRef = React.createRef();
        this.deliveryComponentRef = React.createRef();
        this.itemComponentRef = React.createRef();
        //Variable declare for Default initial base price onload
        this.initialDefaultBasePrice = React.createRef();
        if (this.props.checkoutConfig.defaultCountryId !== "CA") {
            this.initialDefaultBasePrice.current = this.props.checkoutConfig.totalsData.base_subtotal_incl_tax;
        } else if (this.props.checkoutConfig.defaultCountryId === "CA") {
            this.initialDefaultBasePrice.current = this.props.checkoutConfig.totalsData.base_subtotal;
        }

        //#gta4
        if (!window.gta4Versions) { window.gta4Versions = {}; }
        window.gta4Versions.reactApp = '0.29a';
        window.gta4React = getGoogleAnalytics4();

        //get product categories etc
        let productText = localStorage.getItem("itemsAddedToCart");
        if (productText) {
            window.productList = JSON.parse(productText);
        } else {
            window.productList = [];
        }

        const { customerData, defaultCountryId } = this.props.checkoutConfig;
        const {
            firstname = '',
            lastname = '',
            email = '',
            default_shipping: defaultShippingId,
            addresses = []
        } = customerData;
        const mobileNumber = get(customerData, 'custom_attributes.mobile.value', false);
        const defaultShippingAddress = parseDefaultAddress(addresses.find(address => address.id === defaultShippingId));
        this.state = {
            checkoutConfig: this.props.checkoutConfig,
            checkoutApp: this.props.checkoutApp,
            pendingRequests: 0, // Keep track of number of pending requests - used to display loading graphic
            pendingRequestsBlockUI: false,
            adyenIsLoading: false,
            payNowEnabled: true,
            isLoggedIn: this.props.checkoutConfig.isCustomerLoggedIn && !this.props.checkoutConfig.isWebposGuest,
            isGuest: this.props.checkoutConfig.quoteData.is_express || this.props.checkoutConfig.isWebposGuest || false,
            customer: {
                firstname: { value: firstname || '', valid: true, visited: false },
                lastname: { value: lastname || '', valid: true, visited: false },
                email: { value: email, valid: true, visited: false },
                telephone: { value: mobileNumber || defaultShippingAddress.telephone, valid: true, visited: false },
                company: { value: defaultShippingAddress.company, valid: true },
                password: '',
                signUpNewsletter: false
            },
            address: {
                street: [{
                    value: defaultShippingAddress.street,
                    label: 'Street',
                    valid: true,
                    placeholder: 'Street address',
                    showMessage: true
                }, {
                    value: '',
                    label: 'Street 2',
                    valid: true,
                    placeholder: 'Street address 2',
                    showMessage: true
                }],
                suburb: {
                    value: defaultShippingAddress.suburb,
                    label: getSuburbLabelForCountry(defaultCountryId),
                    valid: true,
                    errorMessage: '',
                    showMessage: true,
                    placeholder: `Enter ${getSuburbLabelForCountry(defaultCountryId)} or postcode`
                },
                region: {
                    value: defaultShippingAddress.region,
                    label: getRegionLabelForCountry(defaultCountryId),
                    valid: true,
                    placeholder: getRegionLabelForCountry(defaultCountryId),
                    disabled: this.props.checkoutConfig.enable_custom_address_capture
                },
                postcode: {
                    value: defaultShippingAddress.postcode,
                    label: 'Postcode',
                    valid: true,
                    placeholder: '',
                    disabled: this.props.checkoutConfig.enable_custom_address_capture
                },
                singleLine: {
                    value: '',
                    valid: true,
                    placeholder: 'Enter your address'
                },
                atl_signature: false,
                atl_instructions: '',
                atl_valid: true,
                invalidAddressError: ''
            },
            modifierCodes: [],
            termsAndConditionsChecked: false,
            showTermsAndConditionsRequiredError: false,
            // If user coming from PayPal Express, show the full address input form
            displayOneLineAddress: (this.props.checkoutConfig.quoteData.is_express && false) || true,
            allowFullAddressForm: this.props.checkoutConfig.enable_custom_address_capture,
            shippingMethods: [],
            selectedShippingMethod: '',
            stockists: [],
            selectedClickCollectStore: '', // This is just tracking the value, it isn't controlling
            visibleDeliveryTab: '', // This is just tracking the value, it isn't controlling
            activeSection: this.props.checkoutConfig.quoteData.is_express ? HeaderTabs.CONST__DELIVERY_LINK : HeaderTabs.CONST__BAG_LINK,
            errorMessages: [],
            successMessages: [],
            findInStoreLocation: '', // The location that the user has selected to search for stores in
            // The store that has been selected - from the PDP. Not using selectedClickCollectStore state property from above as they're slightly different meanings
            findInStoreSelected: '',
            reviewItems: false, // Flag to indicate if the "Please review items" message should be displayed, e.g. because users' cart changed
            canSaveState: true, // Flag to indicate if the state can be saved for hydration
            showAutoCompleteSuburb: this.props.checkoutConfig.enable_custom_address_capture
        };

        this.fetchShippingMethodsDebounced = debounce(this.fetchShippingMethods, 400);
        this.fetchShippingPaymentMethodsDebounced = debounce(this.fetchShippingPaymentMethods, 400);
        this.validateManualAddressViaExperianDebounced = debounce(this.validateManualAddressViaExperian, 400);
    }

    /**
     * Hydrate component state with data that has been saved using persistent storage
     *
     * @returns {null}
     */
    async hydrateState() {
        const searchedLocation = await Storage.getItem('find-in-store__search-location');
        // Storage.removeItem('find-in-store__search-location');
        const selectedStoreId = await Storage.getItem('find-in-store__pickup-location');
        // Storage.removeItem('find-in-store__pickup-location');
        const storedState = await Storage.getItem(`fn-checkout-app-state-${this.state.checkoutConfig.quoteId}`);

        // Remove quoteItemData from the storedStore, and make sure that doesn't get hydrated into the current application state
        const { quoteData, ...hydrate } = storedState || { quoteData: false };
        const expressAddress = this.state.checkoutConfig.quoteData.express_address;
        if (expressAddress) {
            // Delete address fetched from localStorage so that address from PayPal is shown
            delete hydrate.address;
        }

        // Handle express address for UK store
        const shouldShowRegion = featureFlags(this.state.checkoutConfig.defaultCountryId).SHOULD_SHOW_REGION;
        const expressCity = expressAddress && expressAddress.city;
        const expressRegion = expressAddress && expressAddress.region;

        const expressSuburbValue = shouldShowRegion ?
            expressCity : [expressCity, expressRegion].filter(Boolean).join(" ");

        const expressRegionValue = shouldShowRegion ?
        {
            ...this.state.address.region,
            value: expressRegion
        }
        : null;

        this.setState({
            // Conditionally hydrate from PayPal express first
            ...expressAddress && {
                customer: {
                    ...this.state.customer,
                    firstname: {
                        ...this.state.customer.firstname,
                        ...this.state.checkoutConfig.quoteData.express_address.firstname && {
                            value: this.state.checkoutConfig.quoteData.express_address.firstname,
                            visited: true
                        }
                    },
                    lastname: {
                        ...this.state.customer.lastname,
                        ...this.state.checkoutConfig.quoteData.express_address.lastname && {
                            value: this.state.checkoutConfig.quoteData.express_address.lastname,
                            visited: true
                        }
                    },
                    email: {
                        ...this.state.customer.email,
                        // Handle instances where the data might be null
                        ...this.state.checkoutConfig.quoteData.express_address.email && {
                            value: this.state.checkoutConfig.quoteData.express_address.email,
                            visited: true
                        }
                    }
                },
                address: {
                    ...this.state.address,
                    street: [{
                        ...this.state.address.street[0],
                        value: this.state.checkoutConfig.quoteData.express_address.street[0]
                    }, {
                        ...this.state.address.street[1],
                        value: this.state.checkoutConfig.quoteData.express_address.street[1] || ''
                    }],
                    suburb: {
                        ...this.state.address.suburb,
                        value: expressSuburbValue
                    },
                    region: expressRegionValue,
                    postcode: {
                        ...this.state.address.postcode,
                        value: this.state.checkoutConfig.quoteData.express_address.postcode
                    }
                },
                displayOneLineAddress: false
            },

            // If there is store state, spread the object into applications' state
            ...storedState !== null && {
                ...hydrate,
                // If the activeSection in stored state is the payment section, need to change to delivery, as payment
                // details won't be available until the user selects a shipping method
                ...(hydrate.activeSection === HeaderTabs.CONST__PAYMENT_LINK || hydrate.activeSection === HeaderTabs.CONST__ACCOUNT) && {
                    activeSection: HeaderTabs.CONST__DELIVERY_LINK
                },
                // If the current quote data doesn't match previous quote data, make sure the user sees the new bag
                ...(quoteData !== false && this.state.checkoutConfig.quoteData.items_qty !== quoteData.items_qty) && {
                    activeSection: HeaderTabs.CONST__BAG_LINK,
                    reviewItems: true
                }
            },

            ...searchedLocation !== null && {
                visibleDeliveryTab: 'cnc',
                findInStoreLocation: searchedLocation.location
            },

            ...selectedStoreId !== null && {
                findInStoreSelected: selectedStoreId.storeId.toString()
            }
        }, () => {
            if (this.state.visibleDeliveryTab === Delivery.CONST__TAB_CNC) {
                this.handleDeliveryTabChange(Delivery.CONST__TAB_CNC);
            }

            // If the user is coming from the PDP, then need to make sure the available Click Collect stores are populated
            if (this.state.findInStoreLocation !== '') {
                this.handleSearchClickCollectStores({ search: this.state.findInStoreLocation });
            }

            // If address is filled by paypal, then check that the suburb, state and postcode match
            const customerHasStoredAddress = this.state.checkoutConfig.customerData.addresses
                && this.state.checkoutConfig.customerData.addresses.length > 0;
            if (!this.state.displayOneLineAddress &&
                !customerHasStoredAddress &&
                this.props.checkoutConfig.enable_custom_address_capture
            ) {
                this.checkPostcodeCombination();
            }

            this.validateManualAddressViaExperianDebounced();
        });
    }

    componentDidMount() {
        this.hydrateState();
        this.fetchShippingMethodsDebounced();
    }

    /**
     * Lifecycle method invoked immediately after updating occurs
     *
     * @returns {null}
     */
    componentDidUpdate() {
        // Grab parts of state that should be persisted across checkout reloads, and save them to storage
        const stateToPersist = {
            // quoteData doesn't get hydrated, it's only used to check if the quoteData user is looking at matches
            // what they've seen previously - if not they need to be taken to the bag section
            quoteData: this.state.checkoutConfig.quoteData,
            isGuest: this.state.isGuest,
            customer: {
                ...this.state.customer
            },
            address: {
                ...this.state.address
            },
            displayOneLineAddress: this.state.displayOneLineAddress,
            activeSection: this.state.activeSection,
            visibleDeliveryTab: this.state.visibleDeliveryTab,
            findInStoreLocation: this.state.findInStoreLocation,
            findInStoreSelected: this.state.findInStoreSelected
        };

        if (this.state.canSaveState) {
            Storage.setItem(`fn-checkout-app-state-${this.state.checkoutConfig.quoteId}`, JSON.stringify(stateToPersist));
        }
    }

    /**
     * Set the pending requests count in state
     *
     * @param {int} modifier Either 1 or -1, for increasing/decreasing
     * @returns {Promise<any>}
     */
    setPendingRequestsCount = (modifier) => {
        return new Promise((res) => {
            const newCount = this.state.pendingRequests + modifier;

            this.setState({
                pendingRequests: Math.max(newCount, 0)
            }, () => {
                res();
            });
        });
    };

    /**
     * Increase the count of pending requests in state
     *
     * @returns {Promise}
     */
    increasePendingRequestsCount = () => {
        return this.setPendingRequestsCount(1);
    };

    /**
     * Decrease the count of pending requests in state
     *
     * @returns {Promise}
     */
    decreasePendingRequestsCount = () => {
        return this.setPendingRequestsCount(-1);
    };

    /**
     * Increase the count of pending requests in state
     *
     * @returns {Promise}
     */
    increasePendingRequestsCountAndBlockUI = () => {
        this.setPendingRequestsBlockUI();
        return this.setPendingRequestsCount(1);
    };

    /**
     * Decrease the count of pending requests in state
     *
     * @returns {Promise}
     */
    decreasePendingRequestsCountAndUnblockUI = () => {
        this.unsetPendingRequestsBlockUI();
        return this.setPendingRequestsCount(-1);
    };

    /**
     * This functions allows the selected shipping method value to be cleared synchronously
     *
     * @returns {Promise}
     */
    clearSelectedShippingMethod = () => {
        return new Promise((res) => {
            this.setState({
                selectedShippingMethod: ''
            }, () => {
                res();
            });
        })
    };

    /**
     * Add an error message or error messages to the global error messages state array
     *
     * @param {Object|array} messages A single errror message object or array of error message objects
     *
     * @returns {null}
     */
    addErrorMessage = (messages) => {
        // If the provided messages is a single error, wrap it in an array so it will spread out into errorMessages consistently
        const errors = Array.isArray(messages) ? messages : [messages];

        this.setState({
            errorMessages: [
                ...errors
            ]
        });

        // check if we need to go to top for desktop
        if (window.innerWidth > 767) {
            window.scrollTo(0, 0);
        }
    };

    addSuccessMessage = (messages) => {
        const success = Array.isArray(messages) ? messages : [messages];

        this.setState({
            successMessages: [
                ...success
            ]
        });

        // check if we need to go to top for desktop
        if (window.innerWidth > 767) {
            window.scrollTo(0, 0);
        }
    };

    /**
     * Clear the error message state array
     *
     * @returns {Promise}
     */
    clearErrorMessages = () => {
        return new Promise((res) => {
            this.setState({
                errorMessages: [],
                showNoAddressFeedback: false,
                errorSelectedClickCollectStore: ''
            }, () => {
                res();
            });
        });
    };

    clearSuccessMessages = () => {
        return new Promise((res) => {
            this.setState({
                successMessages: []
            }, () => {
                res();
            })
        })
    }

    /**
     * Toggles error message for selected click and collect store
     */
    setClickCollectErrorMessage = (message) => {
        return new Promise((res) => {
            this.setState({
                errorSelectedClickCollectStore: message
            }, () => {
                res();
            });
        });
    };

    /**
     * Update the totals data in state
     * Moved to own function so that it can be called from different contexts
     *
     * @param {object} newTotals The new totals values
     *
     * @returns {null}
     */
    updateTotals = (newTotals) => {
        const { free_shipping_available, free_shipping_percentage, free_shipping_remaining } = newTotals.extension_attributes;
        const freeShippingInfo = {
            free_shipping_available: typeof free_shipping_available !== 'undefined' ?
                free_shipping_available :
                this.state.checkoutConfig.free_shipping_available,
            free_shipping_percentage: typeof free_shipping_percentage !== 'undefined' ?
                free_shipping_percentage :
                this.state.checkoutConfig.free_shipping_percentage,
            free_shipping_remaining: typeof free_shipping_remaining !== 'undefined' ?
                free_shipping_remaining :
                this.state.checkoutConfig.free_shipping_remaining
        };

        this.setState({
            checkoutConfig: {
                ...this.state.checkoutConfig,
                totalsData: {
                    ...this.state.checkoutConfig.totalsData,
                    ...newTotals
                },
                ...freeShippingInfo
            }
        });
        const taxAmt = this.state.checkoutConfig.totalsData.tax_amount;
        const hasExpressAddress = this.state.checkoutConfig.quoteData.express_address;
        //check if tax amount is null due to address from paypal
        if (hasExpressAddress && taxAmt === 0) {
            this.setState({ displayOneLineAddress: true });
        }
    };

    updatePaymentMethods = (paymentMethods) => {
        this.setState({
            checkoutConfig: {
                ...this.state.checkoutConfig,
                paymentMethods
            },
            ...!isMobileUi() && { activeSection: HeaderTabs.CONST__PAYMENT_LINK }
        }, () => {
            this.paymentComponentRef.current.updateAdyenForm();
        });
    };

    /**
     * Handle the event fired when the back to Login button is clicked
     *
     * @returns {null}
     */
    handleUserIsGuest = () => {
        const { customerData, defaultCountryId } = this.props.checkoutConfig;
        //need to remove this condition once "Payment section for AU is not loading" is fix
        if (defaultCountryId === 'AU') {
            this.updatePaymentMethods([])
        }

        const {
            firstname = '',
            lastname = '',
            email = '',
            default_shipping: defaultShippingId,
            addresses = []
        } = customerData;
        const mobileNumber = get(customerData, 'custom_attributes.mobile.value', false);
        const defaultShippingAddress = parseDefaultAddress(addresses.find(address => address.id === defaultShippingId));

        const shouldShowRegion = featureFlags(defaultCountryId).SHOULD_SHOW_REGION;

        const regionValue = shouldShowRegion ?
        {
            value: defaultShippingAddress.region,
            label: getRegionLabelForCountry(defaultCountryId),
            valid: true,
            placeholder: getRegionLabelForCountry(defaultCountryId),
            disabled: this.props.checkoutConfig.enable_custom_address_capture
        }
        : null;

        this.setState({
            customer: {
                firstname: { value: firstname || '', valid: true, visited: false },
                lastname: { value: lastname || '', valid: true, visited: false },
                email: { value: email, valid: true, visited: false },
                telephone: { value: mobileNumber || defaultShippingAddress.telephone, valid: true, visited: false },
                company: { value: defaultShippingAddress.company, valid: true },
                password: '',
                signUpNewsletter: false
            },
            address: {
                street: [{
                    value: defaultShippingAddress.street,
                    label: 'Street',
                    valid: true,
                    placeholder: 'Street address',
                    showMessage: true
                }, {
                    value: '',
                    label: 'Street 2',
                    valid: true,
                    placeholder: 'Street address 2',
                    showMessage: true
                }],
                suburb: {
                    value: defaultShippingAddress.suburb,
                    label: getSuburbLabelForCountry(defaultCountryId),
                    valid: true,
                    errorMessage: '',
                    showMessage: true,
                    placeholder: `Enter ${getSuburbLabelForCountry(defaultCountryId)} or postcode`
                },
                region: regionValue,
                postcode: {
                    value: defaultShippingAddress.postcode,
                    label: 'Postcode',
                    valid: true,
                    placeholder: '',
                    disabled: this.props.checkoutConfig.enable_custom_address_capture
                },
                singleLine: {
                    value: '',
                    valid: true,
                    placeholder: 'Enter your address'
                },
                atl_signature: false,
                atl_instructions: '',
                atl_valid: true,
                invalidAddressError: ''
            },
            shippingMethods: [],
            isGuest: false,
            activeSection: HeaderTabs.CONST__DELIVERY_LINK
        }, () => {
            // window.scrollTo(0, 0);
        });
    }

    handleUserLoggedIn = async () => {

        //ga4 datalayer push
        if (gta4App) {
            gta4App.pushGTMLogin({ method: 'Checkout' });
        }

        // Instruct minicart to reload if it changes and customer goes back
        this.setReloadMinicartCookie();

        // Disable state saving and then remove the saved state
        // This ensures there is no race condition where the state might be updated at some point between removing saved state and disabling saved state
        this.setState({
            canSaveState: false
        }, () => {
            // Remove state hydration so that when reloading the checkout, the customer details will pre-fill
            Storage.removeItem(`fn-checkout-app-state-${this.props.checkoutConfig.quoteData.entity_id}`);
            Storage.removeItem(`fn-checkout-delivery-state-${this.props.checkoutConfig.quoteData.entity_id}`);

            window.location.reload();
        });
    };

    /**
     * Handle the event fired when the Checkout as Guest button is clicked
     *
     * @returns {null}
     */
    handleGuestCheckout = () => {
        // ga4 - Event 12 - use case 1
        // Add event listener to Checkout As Guest button
        const gta4App = window.gta4App;
        if (gta4App) {
            gta4App.pushGTMSelectContent({ contentType: "Button", itemID: "Checkout as Guest" });
        }

        this.setState({
            isGuest: true,
            activeSection: HeaderTabs.CONST__DELIVERY_LINK
        }, () => {
            //window.scrollTo(0, 0);
        });
    };


    /**
     * Validate that all of the product having qty
     *
     * @returns {object}
     */
    checkisProductValid = async () => {
        try {
            const response = await fetch(BASE_URL + 'omni_inventory/api/checkinventory');
            let controllerData = await response.json();
            if (controllerData && controllerData.data && controllerData.data.length > 0) {
                let dataArray = controllerData.data;
                return dataArray;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    /**
     * Validate that all of the required customer details fields have been filled in
     *
     * @returns {Promise}
     */
    validateCustomerDetails = (fieldName) => {
        const { email, telephone } = this.state.customer;

        const firstnameValidResult = this.validateFirstName(fieldName);
        const lastnameValidResult = this.validateLastName(fieldName);

        const customerValid = (
            firstnameValidResult.isFirstnameValid &&
            lastnameValidResult.isLastnameValid &&
            isValidEmail(email.value) &&
            isValidTelephone(telephone.value)
        );

        return new Promise((res) => {
            // Update the `valid` properties of each object - this will remove errors as they're cleared also
            this.setState({
                customer: {
                    ...this.state.customer,
                    firstname: {
                        ...this.state.customer.firstname,
                        valid: firstnameValidResult.isFirstnameValid,
                        errorMessage: firstnameValidResult.firstnameErrorMessage
                    },
                    lastname: {
                        ...this.state.customer.lastname,
                        valid: lastnameValidResult.isLastnameValid,
                        errorMessage: lastnameValidResult.lastnameErrorMessage
                    },
                    email: {
                        ...this.state.customer.email,
                        valid: this.validateEmailName(fieldName)
                    },
                    telephone: {
                        ...this.state.customer.telephone,
                        valid: this.validateTelephoneNo(fieldName)
                    }
                }
            }, () => {
                res(customerValid);
            });
        });
    };

    validateFirstName = (fieldName) => {
        const { defaultCountryId } = this.props.checkoutConfig;

        const { isFirstnameValid, firstnameErrorMessage } = validateNameFields(this.state.customer, defaultCountryId);

        if (fieldName === 'address' || fieldName === 'firstname') {
            return { isFirstnameValid, firstnameErrorMessage };
        } else {
            if (this.state.address.singleLine.value && !isFirstnameValid) {
                return { isFirstnameValid, firstnameErrorMessage };
            } else if (this.state.isLoggedIn) {
                return { isFirstnameValid, firstnameErrorMessage }
            } else {
                return isFirstnameValid
                    ? { isFirstnameValid: this.state.customer.firstname.visited, firstnameErrorMessage }
                    : { isFirstnameValid: !this.state.customer.firstname.visited, firstnameErrorMessage };
            }
        }
    }

    validateLastName = (fieldName) => {
        const { defaultCountryId } = this.props.checkoutConfig;

        const { isLastnameValid, lastnameErrorMessage } = validateNameFields(this.state.customer, defaultCountryId);

        if (fieldName === 'address' || fieldName === 'lastname') {
            return { isLastnameValid, lastnameErrorMessage }
        } else {
            if (this.state.address.singleLine.value && !isLastnameValid) {
                return { isLastnameValid, lastnameErrorMessage };
            } else if (this.state.isLoggedIn) {
                return { isLastnameValid, lastnameErrorMessage }
            } else {
                return isLastnameValid
                    ? { isLastnameValid: this.state.customer.lastname.visited, lastnameErrorMessage }
                    : { isLastnameValid: !this.state.customer.lastname.visited, lastnameErrorMessage };
            }
        }
    }

    validateEmailName = (fieldName) => {
        if (fieldName == 'address' || fieldName == 'email') {
            return emailRegex.test(this.state.customer.email.value);
        } else {
            if (this.state.address.singleLine.value && !emailRegex.test(this.state.customer.email.value)) {
                return false;
            } else if (this.state.isLoggedIn) {
                return emailRegex.test(this.state.customer.email.value) ? true : false;
            } else {
                return emailRegex.test(this.state.customer.email.value) ? this.state.customer.email.visited : !this.state.customer.email.visited;
            }
        }

    }

    validateTelephoneNo = (fieldName) => {
        if (fieldName == 'address' || fieldName == 'telephone') {
            return telephoneRegex.test(this.state.customer.telephone.value);
        } else {
            if (this.state.address.singleLine.value && !telephoneRegex.test(this.state.customer.telephone.value)) {
                return false
            } else if (this.state.isLoggedIn) {
                return telephoneRegex.test(this.state.customer.telephone.value) ? true : false;
            } else {
                return telephoneRegex.test(this.state.customer.telephone.value) ? this.state.customer.telephone.visited : !this.state.customer.telephone.visited;
            }
        }

    }

    validateStreet = () => {
        if (this.state.address.street[0].value == '') {
            let updatedAddress = { ...this.state.address };
            updatedAddress.street[0].valid = false;
            this.setState({
                address: updatedAddress
            })
        }
    }

    /**
     * Validate all of the manual address fields are filled in, and if so, send request to server to get shipping options
     *
     * @returns {Promise}
     */
    validateAddressDetails = () => {
        const { defaultCountryId } = this.props.checkoutConfig;

        const { displayOneLineAddress, address } = this.state;
        const { street } = address;
        const {
            street1IsValid,
            street2IsValid,
            street1Message,
            street2Message
        } = this.validateStreetFields(street.map(streetField => streetField.value));

        const addressValid = street1IsValid && street2IsValid;

        return new Promise((res) => {
            // Update the `valid` properties of each object - this will remove errors as they're cleared also
            this.setState({
                address: {
                    ...address,
                    street: [{
                        ...street[0],
                        valid: street1IsValid,
                        errorMessage: street1Message,
                    }, {
                        ...street[1],
                        valid: street2IsValid,
                        errorMessage: street2Message,
                    }],
                    invalidAddressError: street1IsValid && street2IsValid ? '' : INVALID_ADDRESS_ERROR_MESSAGE
                },
                // If there is an issue with the users address, show the full form (NZ only) so the actual erroneous field is visible
                displayOneLineAddress: !addressValid && defaultCountryId === 'NZ' ? false : displayOneLineAddress
            }, () => {
                res(addressValid);
            });
        });
    };

    /**
     * Validates the street field of an address
     *
     * @param street {[string]} string to be validated
     * @returns {object}    object.street1Valid    whether the street 1 field is valid
     *                      object.street1Message  error message for street 1 field
     *                      object.street2Valid    whether the street 2 field is valid
     *                      object.street1Message  error message for street 2 field
     */
    validateStreetFields = (street) => {
        const { defaultCountryId } = this.props.checkoutConfig;
        const allowedCharactersRegEx = /^[\s\da-zA-Z#.,;:'°/()-ø]*$/;  //#early version
        //const allowedCharactersRegEx = /^[\s\da-zA-Z#.,;:'°/()-]*$/;  //#gta4 version

        const maxLengthMap = { 'AU': 40, 'NZ': 40, 'CA': 40 };
        const maxLength = maxLengthMap[defaultCountryId];
        const combinedMaxLengthMap = { 'NZ': 40 };
        const combinedMaxLength = combinedMaxLengthMap[defaultCountryId] || Infinity;

        const combinedLength = `${street[0]} ${street[1]}`.length;
        if (combinedLength > combinedMaxLength) {
            return {
                street1IsValid: false,
                street2IsValid: false,
                street1Message: `Combined street line address must be less than ${maxLength} characters`,
                street2Message: `Combined street line address must be less than ${maxLength} characters`
            }
        }

        const getValidity = (street, required) => {
            let isValid = true;
            let message = '';

            if (required && street === '') {
                isValid = false;
                message = 'This is a required field';
            } else if (street.length > maxLength) {
                isValid = false;
                message = `Please enter less than or equal to ${maxLength} characters`;
            } else if (!allowedCharactersRegEx.test(street)) {
                isValid = false;
                message = 'Street contains illegal characters';
            } else if (Array.isArray(street) && required && street[0] === '') {
                isValid = false;
                message = 'This is a required field'
            }

            return { isValid, message };
        }

        const street1Validity = getValidity(street[0], true);
        const street2Validity = getValidity(street[1], false);

        return {
            street1IsValid: street1Validity.isValid,
            street1Message: street1Validity.message,
            street2IsValid: street2Validity.isValid,
            street2Message: street2Validity.message
        }
    };

    /**
     * Validate that a value exists for shipping method
     *
     * @returns {boolean}
     */
    validateShippingMethod = () => {
        return this.state.selectedShippingMethod !== '';
    };

    /**
     * Validate that a value exists for click and collect store
     *
     * @returns {boolean}
     */
    validateClickCollect = () => {
        const { selectedClickCollectStore, stockists } = this.state;
        return selectedClickCollectStore !== '' && stockists.find(store => store.id === selectedClickCollectStore).click_collect_available;
    };

    /**
     * Validate that stock levels match for products in the cnc store
     *
     * @returns {boolean}
     */
    validateClickCollectStock = () => {
        const { stockists, checkoutConfig } = this.state;
        if (!stockists || stockists.length<=0 || !checkoutConfig || !checkoutConfig.quoteItemData) { return false; }
        const productStock = stockists[0].product_stock;
        const productRequest = checkoutConfig.quoteItemData;
        for (let i=0; i<productRequest.length; i++) {
            for (let j=0; j<productStock.length; j++) {
                if (productStock[j].product_id===productRequest[j].sku) {
                    //found matching product in store
                    if (productStock[j].stock_level<productRequest[j].qty) {
                        //not enough stock
                        return false;
                    }
                }
            }
        }
        return true;
    };

    /**
     * Check if the Authority to Leave value is set, if required
     *
     * @returns {boolean}
     */
    validateAuthorityToLeave = () => {
        return true; // DR-5177 ATL is disabled for launch, leaving code as it will be switched back on afterwards

        const {
            atl_signature: atlSignature,
            atl_instructions: atlInstructions
        } = this.state.address;

        if (this.state.visibleDeliveryTab === Delivery.CONST__TAB_CNC || this.state.checkoutConfig.quoteData.is_virtual) {
            return true;
        }

        const atlValid = atlSignature || atlInstructions !== '';

        this.setState({
            address: {
                ...this.state.address,
                atl_valid: atlValid
            }
        });

        return atlValid;
    };

    /**
     * Validate the quote by sending a request to the server
     *
     * @param {string} paymentMethodCode The code of the payment method that is being used to make payment
     *
     * @returns {Promise<boolean>}
     */
    validateQuote = async (paymentMethodCode) => {
        // MS-2633 Below line is added to start loader on request count when payment button clicked
        await this.increasePendingRequestsCountAndBlockUI();
        const { quoteId, storeCode, defaultCountryID } = this.state.checkoutConfig;
        const { address, customer, isLoggedIn } = this.state;

        const paymentMethod = {
            additional_data: {
                payment_session: this.props.checkoutConfig.payment.forevernew_adyen.paymentSession,
                quote_payment_token: this.props.checkoutConfig.payment.forevernew_adyen.quotePaymentToken
            },
            method: paymentMethodCode,
            po_number: null
        };
        const billingAddress = processBillingAddressForCheckout(customer, address, defaultCountryID);

        try {
            await validateQuote(storeCode, quoteId, customer.email.value, billingAddress, paymentMethod, isLoggedIn);
            // MS-2633 Below line is added for loader on decrease pending request count when payment button clicked
            // this.setState({payNowEnabled: false})
            await this.decreasePendingRequestsCountAndUnblockUI();
        } catch ({ response }) {
            // MS-2633 Below line is added to throw an error if no action performed
            await this.decreasePendingRequestsCountAndUnblockUI();
            this.addErrorMessage({ message: get(response, 'data.message', 'Unable to process order') });
            // this.setState({payNowEnabled: true})
            // String comparison
            // \ForeverNew\Checkout\Model\QuoteValidation::validateQuote()
            if (response.data.message === 'This checkout session is outdated, please press OK to refresh the session. You have not been charged.') {
                alert(response.data.message);
                window.location.reload();
            }
            return false;
        }

        return true;
    };

    /**
     * Handles payment terms and conditions checkbox toggle
     *
     * @param {Event} e The event object
     *
     * @return {null}
     */
    toggleTermsAndConditions = ({ target: { checked: termsAndConditionsChecked } }) => {
        this.setState({ termsAndConditionsChecked });
    };

    /**
     * Handle the tab change event, keeping track of the visible delivery tab, so validation can be performed on
     * the appropriate delivery method when the time comes
     *
     * @param {string} tab String ID of the tab that is selected
     *
     * @returns {null}
     */
    handleDeliveryTabChange = (tab) => {
        this.setState({
            visibleDeliveryTab: tab
        }, () => {
            this.deliveryComponentRef.current.goToTab(tab === Delivery.CONST__TAB_CNC ? 1 : 0);
        });
    };

    handleManualAddressChange = ({ target }) => {
        const { address } = this.state;
        let updatedAddress = {};

        if (target.name === 'street') {
            updatedAddress = {
                ...address,
                street: [{
                    ...address.street[0],
                    value: target.value
                }, {
                    ...address.street[1]
                }]
            };
        } else if (target.name === 'street2') {
            updatedAddress = {
                ...address,
                street: [{
                    ...address.street[0],
                }, {
                    ...address.street[1],
                    value: target.value
                }]
            };
        } else {
            updatedAddress = {
                ...address,
                [target.name]: {
                    ...this.state.address[target.name],
                    value: target.value
                }
            }
        }

        this.setState({
            address: updatedAddress
        }, async () => {
            if (await this.validateAddressDetails()) {
                await this.fetchShippingPaymentMethodsDebounced();
                this.paymentComponentRef.current.updateAdyenForm();
                this.fetchShippingMethodsDebounced();
                this.validateManualAddressViaExperianDebounced();
            }
        });
    };

    /**
     * Checks if we should validate the address with Experian.
     * This should happen when the user has either selected a saved address or entered a manual address.
     *
     * @return {boolean} Validity of the address
     */
    shouldValidateWithExperian = () => {
        const { address, displayOneLineAddress } = this.state;
        const customerAddresses = this.props.checkoutConfig.customerData.addresses;
        const isAddressBookDisplayed = customerAddresses && customerAddresses.length > 0
        if (displayOneLineAddress && !isAddressBookDisplayed) {
            // User is not using manual form
            return false;
        }

        if (this.state.checkoutConfig.defaultCountryId === 'CA') {
            // We don't want to validate Canadian addresses
            return false;
        }

        const { postcode, region, street, suburb } = address;
        const isPostcodeComplete = !!postcode.value && postcode.valid;
        const isRegionComplete = !!region.value && region.valid;
        const isStreetComplete = !!street[0].value && street[0].valid && street[1].valid;
        const isSuburbComplete = !!suburb.value && suburb.valid;

        return isPostcodeComplete && isRegionComplete && isStreetComplete && isSuburbComplete;
    }

    /**
     * Validate an address via Experian. Experian responds with a confidence score which we then use
     * to display a warning to users if their address looks like it has as mistake.
     */
    validateManualAddressViaExperian = async () => {
        if (!this.shouldValidateWithExperian()) {
            return;
        }

        try {
            const { address } = this.state;
            const streetField = [address.street[0].value, address.street[1].value].filter(Boolean).join(', ');
            const singleLineAddress = `${streetField}, ${address.suburb.value}, ${address.region.value} ${address.postcode.value}`;
            const { data: isValid } = await addressValidate(
                singleLineAddress
            );

            this.setState({
                address: {
                    ...this.state.address,
                    invalidAddressError: isValid ? '' : INVALID_ADDRESS_ERROR_MESSAGE
                }
            });
        } catch(error) {
            console.error(error);

            // If anything goes wrong, we assume the address is valid and don't show the warning
            this.setState({
                address: {
                    ...this.state.address,
                    invalidAddressError: ''
                }
            });
        }
    }

    /**
     * Handle the event fired when the single line input value gets changed
     *
     * Sets single line search and clears current address if exists
     * as the user has now modified the address field for search
     * so that failed input searches or non-selections display appropriate errors
     *
     * @param {object} value The new value of the single line address input
     *
     * @returns {null}
     */
    handleChangeAutoCompleteInput = (event) => {
        const { address } = this.state;
        const { street, suburb, region, postcode, singleLine } = address;

        this.setState({
            address: {
                ...this.state.address,
                ...address,
                street: [{
                    ...street[0],
                    value: '',
                    valid: false
                }, {
                    ...street[1],
                    value: '',
                    valid: false
                }],
                suburb: {
                    ...suburb,
                    value: '',
                    valid: true
                },
                region: {
                    ...region,
                    value: '',
                    valid: true
                },
                postcode: {
                    ...postcode,
                    value: '',
                    valid: true
                },
                singleLine: {
                    ...this.state.address.singleLine,
                    ...singleLine,
                    value: event.target.value
                }
            }
        });
    };

    /**
     * Handle the event fired when customer details fields are updated
     *
     * @param {Event} e The event object
     *
     * @returns {null}
     */
    handleChangeCustomer = ({ target }) => {
        this.setState({
            ...this.state,
            customer: {
                ...this.state.customer,
                [target.name]: {
                    ...this.state.customer[target.name],
                    value: target.value
                }
            }
        }, async () => {
            await this.fetchShippingPaymentMethodsDebounced();
            await this.validateCustomerDetails(target.name);
            this.paymentComponentRef.current.updateAdyenForm();
        });
    };

    /**
     * Handle the onBlur() on customer details input field loses focus
     */
    handleOnBlur = ({ target }) => {
        switch (target.name) {
            case "firstname": {
                const validationResult = validateNameFields(
                    this.state.customer,
                    this.state.checkoutConfig.defaultCountryId
                );
                this.setState({
                    customer: {
                        ...this.state.customer,
                        firstname: {
                            ...this.state.customer.firstname,
                            valid: validationResult.isFirstnameValid,
                            errorMessage: validationResult.firstnameErrorMessage,
                            visited: true
                        },
                    }
                })
                break;
            }
            case "lastname": {
                const validationResult = validateNameFields(
                    this.state.customer,
                    this.state.checkoutConfig.defaultCountryId
                );

                this.setState({
                    customer: {
                        ...this.state.customer,
                        lastname: {
                            ...this.state.customer.lastname,
                            valid: validationResult.isLastnameValid,
                            errorMessage: validationResult.lastnameErrorMessage,
                            visited: true
                        },
                    }
                })
                break;
            }
            case "email": {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        email: {
                            ...this.state.customer.email,
                            valid: emailRegex.test(this.state.customer.email.value) ? true : false,
                            visited: true
                        },
                    }
                })
                break;
            }
            case "telephone": {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        telephone: {
                            ...this.state.customer.telephone,
                            valid: telephoneRegex.test(this.state.customer.telephone.value) ? true : false,
                            visited: true
                        },
                    }
                })
                break;
            }
            default:
                break;
        }
    }

    /**
     * Handle the event fired when a suggested one line address is selected
     *
     * @param {string|object} address The one line string of the selected address, or address object of a saved address
     *
     * @returns {null}
     */
    handleSelectAutosuggest = async (address) => {
        this.clearErrorMessages();
        await this.increasePendingRequestsCount();

        const defaultCountryId = this.state.checkoutConfig.defaultCountryId;
        //restrict for CA
        if (defaultCountryId == 'CA' || typeof address != 'object' || typeof address.value != 'undefined') {

            if (typeof address != 'string' && typeof address.value != 'undefined') {
                address = address.value;
            }
            this.setState({
                address: {
                    ...this.state.address,
                    singleLine: {
                        ...this.state.address.singleLine,
                        value: address
                    }
                }
            });
            const {
                data: {
                    success,
                    message,
                    data: formattedAddress
                }
            } = await addressFormat(this.state.checkoutConfig.defaultCountryId, address);

            if (!success) {
                this.addErrorMessage({ message });
                await this.decreasePendingRequestsCount();
                return;
            }

            const regions = this.props.checkoutApp.components.checkoutProvider.dictionaries.region_id;
            let regionId = formattedAddress.region;
            const selectedRegion = regionId ? regions.filter(region => region.title === regionId)[0] : null;

            // If there is a selected region - which will be available in a dropdown - update regionId to use value of dropdown option
            if (selectedRegion) {
                regionId = selectedRegion.value;
            }

            const street = [
                formattedAddress.street0,
                formattedAddress.street1
            ];

            return new Promise((res) => {
                this.setState({
                    address: {
                        ...this.state.address,
                        street: [{
                            ...this.state.address.street[0],
                            value: street[0]
                        }, {
                            ...this.state.address.street[1],
                            value: street[1]
                        }],
                        suburb: {
                            ...this.state.address.suburb,
                            value: formattedAddress.city
                        },
                        ...selectedRegion && {
                            region: {
                                ...this.state.address.region,
                                value: selectedRegion.value,
                                regionId: regionId
                            }
                        },
                        ...formattedAddress.region && {
                            region: {
                                ...this.state.address.region,
                                value: formattedAddress.region,
                                regionId: regionId
                            }
                        },
                        postcode: {
                            ...this.state.address.postcode,
                            value: formattedAddress.postcode
                        },
                    }
                }, async () => {
                    await this.decreasePendingRequestsCount();
                    this.fetchShippingMethodsDebounced();
                    this.validateManualAddressViaExperianDebounced();
                    res();
                })
            });
        }
        else {
            const street1 = address.street[0];
            const street2 = typeof address.street[1] !== 'undefined' ? address.street[1] : '';
            //Removed the first name and last name showing on new address Ms-3806
            const addresLine = address.inline;
            const concFirslastname = address.firstname.concat(" ", address.lastname, ", ");
            const updatedAddress = addresLine.replace(concFirslastname, "");
            return new Promise((res) => {
                this.setState({
                    address: {
                        ...this.state.address,
                        singleLine: {
                            ...this.state.address.singleLine,
                            value: updatedAddress
                        },
                        street: [{
                            ...this.state.address.street[0],
                            value: street1
                        }, {
                            ...this.state.address.street[1],
                            value: street2
                        }],
                        suburb: {
                            ...this.state.address.suburb,
                            value: address.city
                        },
                        region: {
                            ...this.state.address.region,
                            value: address.region.region

                        },
                        postcode: {
                            ...this.state.address.postcode,
                            value: address.postcode
                        },
                    }
                }, async () => {
                    await this.decreasePendingRequestsCount();
                    this.fetchShippingMethodsDebounced();
                    this.validateManualAddressViaExperianDebounced();
                    res();
                });
            });

        }
    };

    /**
     * Handle the event fired when a user selects a new address from their address book.
     * This needs to be handled differently as the address will need to be passed through the address
     * validation and then formatter function, not just the formatter function
     *
     * @param {string} address Single line representation of the selected address
     *
     * @returns {null}
     */
    handleSelectAddressbook = async (address) => {
        await this.clearErrorMessages();
        await this.increasePendingRequestsCount();
        const { data: { data: addresses } } = await addressLookup(this.state.checkoutConfig.defaultCountryId, address);
        await this.decreasePendingRequestsCount();

        if (addresses.length === 1) {
            this.handleSelectAutosuggest(addresses[0]);
            return;
        }

        // @TODO still need to handle the scenario where a saved address might match more than 1 address in the lookup
        this.addErrorMessage({ message: 'We were unable to find a matching address, please try again' });
    };

    /**
     * Send request to server to parse the address provided and suggest matching full addresses
     *
     * @param {string} address The partial address to search for
     *
     * @returns {Promise}
     */
    handleSearchAddress = async (address) => {
        /*
        //exclude for mobile devices
        if (navigator.userAgentData.mobile || window.matchMedia("(max-width: 767px)").matches) {
            return;
        }
        //check for word length
        if (address.length<4) {
            return;
        }
        //check for non-number before query
        if (address.length<4) {
            let result = false;
            for (let i=0, len=address.length; i<len && !result; i++) {
                result = address[0].match(/[a-z]/i);
            }
            if (!result) {
                return;
            }
        }
        */

        await this.increasePendingRequestsCount();

        try {
            const result = await addressLookup(this.state.checkoutConfig.defaultCountryId, address);
            await this.decreasePendingRequestsCount();
            return result;
        } catch (error) {
            // This catch clause is triggered when a pending request is cancelled for a new one
            await this.decreasePendingRequestsCount();
        }

    };


    handleRemoveAndClickCollectStore = async () => {
        await this.increasePendingRequestsCountAndBlockUI();
        try {
            await removeClickAndCollectStore();
            await this.decreasePendingRequestsCountAndUnblockUI();
        } catch (error) {
            await this.decreasePendingRequestsCountAndUnblockUI();
        }
    }

    /**
     * Function to handle getting the shipping methods available for the users selected address
     *
     * @returns {null}
     */
    fetchShippingMethods = async () => {
        await this.increasePendingRequestsCount();
        try {
            if (this.state.checkoutConfig.quoteData.is_virtual) {
                // If the quote is virtual, then there are not going to be any shipping options to display (the component
                // isn't even rendered)
                const { payment_methods: paymentMethods, totals } = await this.fetchBillingPaymentMethods();
                this.updatePaymentMethods(paymentMethods);
                this.updateTotals(totals);

                await this.decreasePendingRequestsCount();
                return false;
            }

            const { activeCarriers, basePriceFormat, defaultCountryId, quoteId, storeCode } = this.props.checkoutConfig;

            const addressData = featureFlags(defaultCountryId).SHOULD_SHOW_REGION
                ? {
                    country_id: defaultCountryId,
                    postcode: this.state.address.postcode.value,
                    region: this.state.address.region.value,
                    region_id: this.state.address.region.region_id
                }
                : {
                    country_id: defaultCountryId,
                    postcode: this.state.address.postcode.value
                };

            const { data: shippingMethods } = await estimateShippingMethods(storeCode, quoteId, addressData, this.state.isLoggedIn);

            // force set shipping - sets to selected if exists or first method
            // carrier: convert_regular = FREE and ~$8
            // FN business requirement: automatically select shipping method
            const { selectedShippingMethod } = this.state;
            const selectedShippingAvailable = selectedShippingMethod === 'click_collect' || shippingMethods.find(method => method.method_code === selectedShippingMethod);
            const shippingMethod = selectedShippingAvailable ? selectedShippingMethod : shippingMethods[0].carrier_code;
            this.handleSelectShippingMethod(shippingMethod);

            const formattedShippingMethods = buildShippingOptionsArray(
                shippingMethods,
                this.handleSelectShippingMethod,
                {
                    pattern: basePriceFormat.pattern,
                    defaultCountryId,
                    activeCarriers
                }
            );

            this.setState({
                ...this.state,
                shippingMethods: formattedShippingMethods
            }, async () => {
                await this.fetchShippingPaymentMethodsDebounced();
                this.paymentComponentRef.current.updateAdyenForm();
            });
            await this.decreasePendingRequestsCount();

        } catch (error) {
            await this.decreasePendingRequestsCount();
        }
    };

    /**
     * Fetch payment methods (and totals information) by setting the shipping information
     *
     * Payment methods and totals information are returned when setting shipping information
     *
     * @returns {Object}
     */
    fetchShippingPaymentMethods = async () => {
        await this.increasePendingRequestsCount();
        // If there is no shipping method selected, then we can't send request to server to save shipping information
        if (this.state.selectedShippingMethod === '') {
            await this.decreasePendingRequestsCount();
            return false;
        }

        const { quoteId, storeCode } = this.props.checkoutConfig;
        const {
            customer, address, isLoggedIn, checkoutConfig: { defaultCountryId },
            shippingMethods, selectedShippingMethod, selectedClickCollectStoreAddress,
        } = this.state;

        // When shippingAddress is selectedClickCollectStoreAddress it doesn't contain user details
        // Merge user details from billingAddress to shippingAddress
        const billingAddress = processBillingAddressForCheckout(customer, address, defaultCountryId);
        const { firstname, lastname, telephone } = billingAddress;
        const shippingAddress = selectedShippingMethod === 'click_collect' && selectedClickCollectStoreAddress ?
            { ...selectedClickCollectStoreAddress, firstname, lastname, telephone } :
            billingAddress;

        try {
            const { shippingMethods, selectedShippingMethod, isLoggedIn } = this.state;
            //MS-2833 For express shipping the id is 'express' but selectedShippingMethod is 'mcom',so checked against carrier
            const { id: method, carrier } = shippingMethods.find(method => (method.id === selectedShippingMethod || method.carrier === selectedShippingMethod));
            if (this.isInvalidShippingLocation(shippingAddress)) {
                await this.decreasePendingRequestsCount();
                return null;
            }
            const { data } = await setShippingInformation(storeCode, quoteId, shippingAddress, billingAddress, method, carrier, isLoggedIn);
            await this.decreasePendingRequestsCount();
            return data;
        } catch (error) {
            await this.decreasePendingRequestsCount();
        }
    };

    isInvalidShippingLocation = (shippingAddress) => {
        if (!window.shippingAddressFilter) {
            return false;
        }

        const invalidRegions = window.shippingAddressFilter.invalidRegions || [];
        const invalidPostcodeDistricts = window.shippingAddressFilter.invalidPostcodeDistricts || [];

        if (shippingAddress && shippingAddress.city && shippingAddress.postcode) {
            if (invalidRegions.includes(shippingAddress.city)) {
                if (shippingAddress.postcode.length>1) {
                    let match = shippingAddress.postcode.trim();   //match entire postcode by default
                    //check for space or - in postcode
                    const splitSpace = match.split(' ');
                    const splitHypen = match.split('-');
                    if (splitSpace.length>1) {
                        //match postal district only
                        match = splitSpace[0];
                    } else if (splitHypen.length>1) {
                        //match postal district only
                        match = splitHypen[0];
                    }
                    if (invalidPostcodeDistricts.includes(match)) { return true };
                }
            }
        }
        return false;
    };

    /**
     * Fetch payment methods by setting the billing address
     *
     * @returns {Promise<void>}
     */
    fetchBillingPaymentMethods = async () => {
        const { quoteId, storeCode } = this.props.checkoutConfig;
        const address = processBillingAddressForCheckout(this.state.customer, this.state.address, this.state.checkoutConfig.defaultCountryId);
        await this.increasePendingRequestsCount();
        await setBillingAddress(quoteId, address, this.state.isLoggedIn);
        const { data } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);
        await this.decreasePendingRequestsCount();
        return data;
    };

    handlePromoCode = async ({ action, modifiercode }) => {
        const { quoteId, storeCode } = this.props.checkoutConfig;
        const { totalsData: { coupon_code } } = this.state.checkoutConfig;
        // add comma separated list of all coupons which needs to be applied as per the requirement in magento api
        const allCouponCodes = coupon_code ? `${modifiercode},${coupon_code}` : modifiercode;

        // Instruct minicart to reload if it changes and customer goes back
        this.setReloadMinicartCookie();

        await this.increasePendingRequestsCountAndBlockUI();

        if (action === PaymentModifierContainer.CONST__PROMO_APPLY) {
            try {
                const { data: isApplied } = await applyCouponCode(storeCode, quoteId, allCouponCodes, this.state.isLoggedIn);
                if (!isApplied) {
                    await this.decreasePendingRequestsCountAndUnblockUI();
                    return new Promise((res) => {
                        res({
                            success: false,
                            message: 'Unable to apply promo code'
                        });
                    });
                }
            } catch ({ response }) {
                await this.decreasePendingRequestsCountAndUnblockUI();
                const message = get(response, 'data.message');

                return new Promise((res) => {
                    res({
                        success: false,
                        message
                    });
                });
            }
        }

        if (action === PaymentModifierContainer.CONST__PROMO_REMOVE) {
            try {
                // apply all coupon codes except the removed coupon code to remove coupon from quote
                const remainingCoupons = coupon_code.split(',').filter(el => el.toLowerCase() !== modifiercode.toLowerCase())
                const { data: isRemoved } = await removeCouponCode(storeCode, quoteId, remainingCoupons.join(','), this.state.isLoggedIn);
                if (!isRemoved) {
                    await this.decreasePendingRequestsCountAndUnblockUI();
                    return new Promise((res) => {
                        res({
                            success: false,
                            message: 'Unable to remove promo code'
                        });
                    });
                }
            } catch (e) {
                await this.decreasePendingRequestsCountAndUnblockUI();

                return new Promise((res) => {
                    res({
                        success: false,
                        message: 'Unable to remove promo code'
                    });
                });
            }
        }

        // Update order summary values
        if (this.state.selectedShippingMethod !== 'click_collect') { // in theory this should not matter, but the method trigger adyen fetch and cause a race condition
            await this.fetchShippingMethodsDebounced();
            await this.fetchShippingPaymentMethodsDebounced();
        }

        const { data: paymentInformation } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);

        this.updatePaymentMethods(paymentInformation.payment_methods);
        this.updateTotals(paymentInformation.totals);

        this.setState({
            ...this.state,
            checkoutConfig: {
                ...this.state.checkoutConfig,
                totalsData: {
                    ...this.state.checkoutConfig.totalsData,
                    coupon_code: paymentInformation.totals.coupon_code || null
                }
            }
        }, () => {
            this.updateTotals(paymentInformation.totals);
        });

        await this.decreasePendingRequestsCountAndUnblockUI();

        return new Promise((res) => {
            res({
                success: true,
                message: ''
            });
        });
    };

    /**
     * Handle the event fired when a user tries to attach a gift card to their cart
     *
     * @param {object} state The state of the payment modifier that has triggered this function
     *
     * @returns {Promise}
     */
    handleGiftExchangeCard = async ({ modifiercode: code, selection, blackhawkPin, displayGiftcardPin, paymentCodeSession }) => {
        const { storeCode, quoteId } = this.props.checkoutConfig;
        const { address, customer, isLoggedIn } = this.state;
        const paymentMethod = {
            additional_data: {
                payment_session: this.props.checkoutConfig.payment.forevernew_adyen.paymentSession,
                quote_payment_token: this.props.checkoutConfig.payment.forevernew_adyen.quotePaymentToken
            },
            method: paymentCodeSession,
            po_number: null
        };

        // Instruct minicart to reload if it changes and customer goes back
        this.setReloadMinicartCookie();

        await this.increasePendingRequestsCountAndBlockUI();
        const was_express = this.state.checkoutConfig.quoteData.is_express;

        try {
            const pin = displayGiftcardPin ? blackhawkPin : false;
            await applyCard(storeCode, quoteId, code, selection, pin, this.state.isLoggedIn);

            const { data: paymentInformation } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);

            this.updateTotals(paymentInformation.totals);
            this.updatePaymentMethods(paymentInformation.payment_methods);

            await this.decreasePendingRequestsCountAndUnblockUI();

            const { defaultCountryId } = this.props.checkoutConfig;
            // validation after comparison of the subtotal with the subtotal in the previous step, gift card apply asking customer to refresh the page
            if (defaultCountryId !== "CA" && paymentInformation.totals.base_subtotal_incl_tax !== this.initialDefaultBasePrice.current) {
                setTimeout(() => {
                    alert('This checkout session is outdated, please press OK to refresh the session. You have not been charged.');
                    window.location.reload();
                    return false;
                }, 1000);
            } else if (defaultCountryId === "CA" && paymentInformation.totals.base_subtotal !== this.initialDefaultBasePrice.current) {
                setTimeout(() => {
                    alert('This checkout session is outdated, please press OK to refresh the session. You have not been charged.');
                    window.location.reload();
                    return false;
                }, 1000);
            }

            return new Promise((res) => {
                res({
                    success: true,
                    cardId: code,
                    message: ''
                });
            });
        } catch ({ response }) {
            await this.decreasePendingRequestsCountAndUnblockUI();
            // The Adyen form will be marked as updating, which needs to be removed
            this.paymentComponentRef.current.updateAdyenForm();

            return new Promise((res) => {
                res({
                    success: false,
                    cardId: code,
                    message: get(response, 'data.message', 'Unable to apply gift card')
                });
            });
        }
    };

    /**
     * Handle the event fired when a user tries to remove a giftcard/exchange card from their cart
     *
     * @param {string} cardId The giftcard/exchange card ID
     *
     * @returns {Promise}
     */
    handleRemoveGiftExchangeCard = async (cardId) => {
        const { storeCode, quoteId } = this.props.checkoutConfig;

        // Instruct minicart to reload if it changes and customer goes back
        this.setReloadMinicartCookie();

        await this.increasePendingRequestsCountAndBlockUI();

        const remove = await removeCard(storeCode, quoteId, cardId, this.state.isLoggedIn);
        const was_express = this.state.checkoutConfig.quoteData.is_express;

        if (!remove) {
            await this.decreasePendingRequestsCountAndUnblockUI();
            return new Promise((res) => {
                res(false);
            });
        }

        const { data: paymentInformation } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);
        this.updateTotals(paymentInformation.totals);
        this.updatePaymentMethods(paymentInformation.payment_methods);

        if (was_express) {
            this.setState({
                ...this.state,
                checkoutConfig: {
                    ...this.state.checkoutConfig,
                    quoteData: {
                        ...this.state.checkoutConfig.quoteData,
                        is_express: false,
                    }
                }
            });
            this.paymentComponentRef.current.choosePaypalExpressMethod();
        }

        await this.decreasePendingRequestsCountAndUnblockUI();

        return new Promise((res) => {
            res(true);
        });
    };

    /**
     * Hide the one line address input and show the full address input form
     *
     * @returns {null}
     */
    toggleFullForm = () => {
        const { displayOneLineAddress } = this.state;
        this.setState({
            displayOneLineAddress: !displayOneLineAddress
        });
    };

    /**
     * Handle the event thrown when a configurable or quantity for an item is updated
     *
     * @param {object} itemData     The entire item object
     * @param {string} configurable A string representation of the configurable/qty that is updated. Will match 'configurables'
     *                              property set on the itemData object in ItemSummary, passed to ItemSummaryContainer
     * @param {{label: string, value: string}} value  The value object from react-select that the configurable/qty was changed to
     *
     * @returns {Promise}
     */
    handleUpdateConfigurable = async (itemData, configurable, { target: { value } }) => {
        const item = this.state.checkoutConfig.quoteItemData.find(item => item.item_id === itemData.item_id);
        const currentUsedSize = extractConfigurable(item, 'size');
        const currentUsedColour = extractConfigurable(item, 'fashion_colour');
        const sizeOption = Object.values(item.configurable_attributes).find(attr => attr.attribute_code === 'size');
        const sizeOptions = sizeOption ? sizeOption.options : {};
        const colourConfigurable = Object.values(item.configurable_attributes).find(attr => attr.attribute_code === 'fashion_colour');
        const colourOptions = colourConfigurable ? colourConfigurable.options : {};

        // Instruct minicart to reload if it changes and customer goes back
        this.setReloadMinicartCookie();

        const usedAttributes = [];
        // Find the objects for the new size/colour. Technically only one of these will be new, but will check both anyway
        if (configurable === 'size') {
            const newUsedSize = Object.values(sizeOptions).find(option => option.option_title === value);
            usedAttributes.push({
                ...currentUsedSize,
                option_value: newUsedSize.value_index,
                value: newUsedSize.option_title
            });
        } else {
            // Find the attribute for the already used size from the configurable_attributes data
            // Unable to just use the value from used_attribute, as the object has a different structure
            // const newUsedSize = Object.values(sizeOptions).find(option => option.value_index === currentUsedSize.option_value);
            usedAttributes.push(currentUsedSize);
        }

        if (configurable === 'fashion_colour') {
            const newUsedColour = Object.values(colourOptions).find(option => option.option_title === value);
            usedAttributes.push({
                ...currentUsedColour,
                option_value: newUsedColour.value_index,
                value: newUsedColour.option_title
            });
        } else {
            // const newUsedColour = Object.values(colourOptions).find(option => option.value_index === currentUsedColour.option_value);
            usedAttributes.push(currentUsedColour);
        }

        const updatedItem = {
            ...item,
            // Change the value of the configurable in the options property
            options: item.options.map((option) => {
                if (option.label.toLowerCase() !== configurable.toLowerCase()) {
                    return option;
                }

                return {
                    ...option,
                    value
                };
            }),
            // Update the  value of used_attributes
            used_attributes: usedAttributes,
            ...configurable === 'qty' && { qty: value }
        };

        const was_express = this.state.checkoutConfig.quoteData.is_express;

        this.setState({
            ...this.state,
            checkoutConfig: {
                ...this.state.checkoutConfig,
                quoteItemData: this.state.checkoutConfig.quoteItemData.map((item) => {
                    if (itemData.item_id !== item.item_id) {
                        return item;
                    }

                    return {
                        ...updatedItem
                    };
                }),
                quoteData: {
                    ...this.state.checkoutConfig.quoteData,
                    is_express: false,
                }
            }
        }, () => {
            this.postUpdateProduct(updatedItem, itemData, itemData.item_id);
            if (was_express) { // if customer was previously in paypal express flow, select paypal as default payment method
                this.paymentComponentRef.current.choosePaypalExpressMethod();
            }
        });
    };

    /**
     * Send request to server to update the product in the quote.
     * This will be for qty, size and colour
     *
     * @param {object} itemData   The item that is being updates
     * @param {object} oldItemData   The old item that is being updates
     * @param {integer} oldItemId The ID of the item that has been updated - and therefore isn't part of the checkout anymore
     *
     * @returns {null}
     */
    postUpdateProduct = async (itemData, oldItemData, oldItemId) => {
        const { storeCode, quoteId, quoteData } = this.props.checkoutConfig;
        const previousItemData = { ...oldItemData };
        this.clearErrorMessages();
        await this.increasePendingRequestsCountAndBlockUI();

        try {
            const { data: updatedItem } = await updateProduct(storeCode, quoteData.entity_id, itemData, this.state.isLoggedIn);
            //push product data in dataLayer for updateCart item
            window.dataLayer.push({
                'event': 'updateCart',
                'ecommerce': {
                    'update': {
                        'products': [{
                            'name': updatedItem.name,
                            'id': updatedItem.sku,
                            'price': updatedItem.price,
                            'quantity': updatedItem.qty
                        }]
                    }
                }
            });
            // Update state with the data returned from the server
            this.setState({
                ...this.state,
                checkoutConfig: {
                    ...this.state.checkoutConfig,
                    quoteItemData: this.state.checkoutConfig.quoteItemData.map((item) => {
                        if (parseInt(item.item_id, 10) !== parseInt(oldItemId, 10)) {
                            return item;
                        }

                        return {
                            ...item,
                            ...updatedItem
                        };
                    })
                }
            });

            const { data: paymentInformation } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);
            this.updateTotals(paymentInformation.totals);
            //update the default price as should not throw error why validating checkout session for single tab
            this.initialDefaultBasePrice.current = paymentInformation.totals.base_subtotal_incl_tax;
            this.fetchShippingMethods();
            await this.fetchShippingPaymentMethodsDebounced();
            this.paymentComponentRef.current.updateAdyenForm();
            await this.decreasePendingRequestsCountAndUnblockUI();
        } catch
        ({ response: { data: { message } } }) {
            await this.decreasePendingRequestsCountAndUnblockUI();
            this.addErrorMessage({ message });
            setTimeout(() => { this.clearErrorMessages() }, 5000);

            this.setState({
                ...this.state,
                checkoutConfig: {
                    ...this.state.checkoutConfig,
                    quoteItemData: this.state.checkoutConfig.quoteItemData.map((item) => {
                        if (parseInt(item.item_id, 10) === parseInt(itemData.item_id, 10)) {
                            return {
                                ...item,
                                ...previousItemData
                            };
                        }

                        return item;
                    })
                }
            });
        }
    };

    /**
     * Remove an item from the quoteItemData array
     *
     * @param {int} itemId The ID of the item being removed
     * @returns {Promise}
     */
    removeItemFromCart = (itemId) => {
        return new Promise((res) => {
            this.setState({
                ...this.state,
                checkoutConfig: {
                    ...this.state.checkoutConfig,
                    quoteItemData: this.state.checkoutConfig.quoteItemData.filter(item => parseInt(item.item_id, 10) !== itemId),
                    quoteData: {
                        ...this.state.checkoutConfig.quoteData,
                        is_express: false,
                    }
                }
            }, () => {
                res();
            });
        });
    };

    /**
     * Handle removing an item from the checkoutConfig state object
     *
     * @param {int} itemId The ID of the item being removed
     *
     * @returns {Promise}
     */
    handleRemoveItemFromCart = async (itemId) => {
        // Store removed item for reverting in case of error
        const removedItem = this.state.checkoutConfig.quoteItemData.find(item => parseInt(item.item_id, 10) === itemId);

        // Instruct minicart to reload if it changes and customer goes back
        this.setReloadMinicartCookie();

        await this.clearErrorMessages();
        await this.increasePendingRequestsCount();

        try {
            await this.removeItemFromCart(itemId);
            const { cookies } = this.props;
            const formKey = cookies.get('form_key');
            const { data: { success, error_message: error, quote_is_virtual: quoteIsVirtual } } = await removeItem(itemId, formKey);

            if (!success) {
                throw { error };
            }
            else {
                // Measure the removal of a product from a shopping cart.
                window.dataLayer.push({
                    'event': 'removeFromCart',
                    'ecommerce': {
                        'remove': {
                            'products': [{
                                'name': removedItem.name,
                                'id': removedItem.sku,
                                'price': removedItem.price,
                                'quantity': removedItem.qty
                            }]
                        }
                    }
                });
                const removeFromCartAction = {
                    "action": "remove-from-cart",
                    "target": {
                        "product": 'p_' + removedItem.color_product_id
                    },
                }
                xo.activity.send(removeFromCartAction);
            }
            if (this.state.checkoutConfig.quoteItemData.length === 0) {
                // Clean up local storage if there is no items left in cart
                Storage.removeItem(`fn-checkout-app-state-${this.props.checkoutConfig.quoteData.entity_id}`);
                Storage.removeItem(`fn-checkout-delivery-state-${this.props.checkoutConfig.quoteData.entity_id}`);
                Storage.removeItem('find-in-store__search-location');
                Storage.removeItem('find-in-store__pickup-location');

                window.location.replace(this.props.checkoutConfig.cartUrl);
            } else {
                const { findInStoreLocation, findInStoreSelected } = this.state;
                const { quoteId, storeCode } = this.props.checkoutConfig;
                const was_express = this.state.checkoutConfig.quoteData.is_express;
                this.setState({
                    ...this.state,
                    checkoutConfig: {
                        ...this.state.checkoutConfig,
                        quoteData: {
                            ...this.state.checkoutConfig.quoteData,
                            is_virtual: quoteIsVirtual,
                            is_express: false,
                        }
                    }
                });

                if (findInStoreLocation) {
                    await this.handleSearchClickCollectStores({ search: findInStoreLocation });
                    if (findInStoreSelected) {
                        await this.handleSelectClickCollectStore(findInStoreSelected);
                    }
                }

                this.fetchShippingMethods();
                const { data: paymentInformation } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);
                this.updateTotals(paymentInformation.totals);
                //update the default price as should not throw error why validating checkout session for single tab
                this.initialDefaultBasePrice.current = paymentInformation.totals.base_subtotal_incl_tax;
                await this.decreasePendingRequestsCount();
                // this.paymentComponentRef.current.choosePaypalExpressMethod();
            }
        } catch (response) {
            this.addErrorMessage({ message: response.error || 'There was an error removing item from cart' });
            await this.decreasePendingRequestsCount();

            // Place the item that was attempted to be removed back into the array
            this.setState({
                checkoutConfig: {
                    ...this.state.checkoutConfig,
                    quoteItemData: [
                        ...this.state.checkoutConfig.quoteItemData,
                        removedItem
                    ]
                }
            });
        }
    };

    /**
     * Handle the event fired when customer click on "Choose a different payment method"
     */
    cancelExpressCheckout = () => {
        this.setState({
            ...this.state,
            checkoutConfig: {
                ...this.state.checkoutConfig,
                quoteData: {
                    ...this.state.checkoutConfig.quoteData,
                    is_express: false,
                }
            }
        });
        this.paymentComponentRef.current.choosePaypalExpressMethod();
    };

    /**
     * Handle the event fired when a shipping method is selected
     *
     * @param {string} shippingCode The code for the selected shipping method
     *
     * @returns {Promise<void>}
     */
    handleSelectShippingMethod = (shippingCode) => {
        this.clearErrorMessages();
        this.setState({
            ...this.state,
            selectedShippingMethod: shippingCode
        }, async () => {
            if (shippingCode === 'click_collect') {
                this.handleDeliveryTabChange(Delivery.CONST__TAB_CNC);
                return;
            }
            await this.increasePendingRequestsCountAndBlockUI();
            //MS-3125 : Handle exception for invalid address
            try {
                const { payment_methods: paymentMethods, totals } = await this.fetchShippingPaymentMethodsDebounced();
                await this.decreasePendingRequestsCountAndUnblockUI();
                this.updatePaymentMethods(paymentMethods);
                this.updateTotals(totals);
            }
            catch (error) {
                await this.decreasePendingRequestsCountAndUnblockUI();
                this.addErrorMessage({ message: 'Invalid address, please refresh the page and try again.' });

            }

        });
    };

    getSubSiteDirectory = () => {
        let output = '';
        let path = window.location.pathname;
        if (path && path.length > 0) {
            let dirList = path.split('/');
            if (dirList && dirList.length > 0) {
                if (dirList[0] == 'fr') {
                    output = '/fr';
                } else if (dirList.length > 1 && dirList[0] == '' && dirList[1] == 'fr') {
                    output = '/fr';
                }
            }
        }
        return output;
    };

    /**
     * searchObj.search will contain either a postcode, in a string, or a Coordinates object from window.navigator
     *
     * @param {Object} searchObj
     */
    handleSearchClickCollectStores = async (searchObj) => {
        let queryParam;
        if (typeof searchObj.search === 'string') {
            queryParam = `address=${searchObj.search}`;
        } else {
            queryParam = `latitude=${searchObj.search.latitude}&longitude=${searchObj.search.longitude}`;
        }
        await this.increasePendingRequestsCount();
        let subDir = this.getSubSiteDirectory() || '';

        AJAX.request({
            method: 'get',
            url: subDir + `/omnichannel/inventory/find?${queryParam}`
        }).then(async ({ data }) => {
            await this.decreasePendingRequestsCount();
            this.setState({
                findInStoreLocation: searchObj.search,
                stockists: data.data
            });
        });
    };

    /**
     * Handle the event when a Click and Collect store is selected
     *
     * @param {string} storeId The ID of the selected store
     *
     * @returns {Promise}
     */
    handleSelectClickCollectStore = async (storeId) => {
        this.clearErrorMessages();
        await this.increasePendingRequestsCount();
        this.setState({
            ...this.state,
            selectedClickCollectStore: storeId,
            findInStoreSelected: storeId,
            // If the user clicks on the tab rather than the link in Shipping Options, this property wouldn't have been get set
            selectedShippingMethod: 'click_collect'
        }, async () => {
            const { data: { success, message, data: { storeAddress, updateShipping } } } = await setClickAndCollectStore(storeId);

            await this.setClickCollectErrorMessage(message);

            if (!success) {
                this.addErrorMessage({ message });
                await this.decreasePendingRequestsCount();
                return;
            }

            if (updateShipping) {
                const { customer, checkoutConfig: { defaultCountryId } } = this.state;
                await this.setClickAndCollectAddress(processStoreAddressForCheckout(customer, storeAddress, defaultCountryId));
            }
            try {
                await this.fetchShippingMethodsDebounced();
                const { payment_methods: paymentMethods, totals } = await this.fetchShippingPaymentMethodsDebounced();
                this.updatePaymentMethods(paymentMethods);
                this.updateTotals(totals);
                await this.decreasePendingRequestsCount();
            } catch (e) {
                await this.decreasePendingRequestsCount();
            }
        });
    };

    /**
     * Overrides shipping address with click and collect store address for correct tax calculation
     *
     * @param {object} address - click and collect store address
     *
     * @returns {Promise}
     */
    setClickAndCollectAddress = (address) => {
        return new Promise((res, rej) => {
            this.setState({
                selectedClickCollectStoreAddress: { ...address }
            }, () => {
                res();
            });
        });
    };

    /**
     * Record changes in the Authority to leave select
     * @param {string} atl either 'yes' or 'no' depending on whether there is ATL
     */
    handleAuthorityToLeaveChange = (atl) => {
        this.setState({
            ...this.state,
            address: {
                ...this.state.address,
                atl_signature: atl === 'false'
            }
        }, () => {
            // Fetching payment methods is done by submitting shipping information. In this instance, the returned payment
            // methods are just ignored, as I only want to update the quote with Authority To Leave information
            this.fetchShippingPaymentMethodsDebounced();
        });
    };

    /**
     * Record changes in the Authority to leave instructions
     * @param {string} target the textarea element
     */
    handleAuthorityToLeaveNotesChange = ({ target }) => {
        this.setState({
            ...this.state,
            address: {
                ...this.state.address,
                atl_instructions: target.value
            }
        }, () => {
            // Fetching payment methods is done by submitting shipping information. In this instance, the returned payment
            // methods are just ignored, as I only want to update the quote with Authority To Leave information
            this.fetchShippingPaymentMethodsDebounced();
        });
    };

    /**
     * Scroll the page to make sure the highest error is within view
     * E.g. if the personal details are invalid, then the page probably doesn't have to scroll
     * but if the authority to leave field is invalid, then make sure the user can see that it's highlighted as an error
     *
     * @returns {null}
     */
    scrollToFirstError = () => {
        const firstError = document.querySelector('.validation-error');
        if (!firstError) return;
        const offset = firstError.getBoundingClientRect().top;
        if (!isMobileUi()) {
            window.scrollTo(0, offset);
        }
    };

    /**
     * Check that all required fields for a successful checkout are filled in
     * This will check customer details, address and shipping method. Essentially all of the data that is entered into
     * the Delivery column of the checkout
     *
     * @param {boolean} validateTermsAndConditions - default true
     * Void T&C validation when passing through activateSection for payment
     * and user cannot get to view to tick checkbox
     *
     * @returns {boolean}
     */
    isCheckoutValid = async (validateTermsAndConditions = true) => {
        /*MS-3586: Refresh pop up comes when session out for multiple tabs mismatch payment method*/
        const { storeCode, quoteId } = this.props.checkoutConfig;
        const { data: paymentInformation } = await fetchPaymentInformation(storeCode, quoteId, this.state.isLoggedIn);
        const countryId = this.props.checkoutConfig.defaultCountryId;
        if (paymentInformation.totals.base_subtotal_incl_tax === this.initialDefaultBasePrice.current || paymentInformation.totals.base_subtotal === this.initialDefaultBasePrice.current) {

        } else {
            alert('This checkout session is outdated, please press OK to refresh the session. You have not been charged.');
            window.location.reload();
            return false;
        }
        const validAddress = await this.validateAddressDetails();
        const validCustomerDetails = await this.validateCustomerDetails('address');
        const validShipping = this.validateShippingMethod();
        const validClickCollect = this.validateClickCollect();
        const validClickCollectStock = this.validateClickCollectStock();
        const validATL = this.validateAuthorityToLeave();
        const isProductValid = await this.checkisProductValid();
        if(isProductValid){
            var productNames = '';
            isProductValid.map(item => (
                productNames += ', ' + item.name
            ));
            productNames.startsWith(',');
            productNames = productNames.substring(1);
            this.addErrorMessage({ message: 'Some of the product(s) does not have qty' + productNames});
            return false;
        }
        const {
            defaultCountryId,
            quoteData: {
                is_virtual: quoteDataIsVirtual
            }
        } = this.state.checkoutConfig;

        await this.deliveryComponentRef.current.setValidationMessage('shippingMethods', validShipping ? '' : 'Please select a shipping method');
        await this.deliveryComponentRef.current.setValidationMessage('clickAndCollect', validClickCollect ? '' : 'Please select a store to collect');

        const invalidShipping = (this.state.visibleDeliveryTab === Delivery.CONST__TAB_SHIPPING && !validShipping);
        const invalidClickAndCollect = (this.state.visibleDeliveryTab === Delivery.CONST__TAB_CNC && (!validClickCollect || !validClickCollectStock));

        // If isn't virtual quote data and either of the shipping method or click and collect vars are false, then return false
        if (!quoteDataIsVirtual && (invalidShipping || invalidClickAndCollect)) {
            const deliveryError = invalidShipping ?
                'Invalid shipping option, please refresh the page and choose Shipping or Click and Collect' :
                'Some items are not available for Click and Collect - please remove those items or change to standard shipping.';

            this.scrollToFirstError();
            this.addErrorMessage({ message: deliveryError });
            console.log('Invalid shipping option, selected state: ' + this.state.visibleDeliveryTab);
            return false;
        }

        // T&C required for CA store
        const showTermsAndConditionsRequiredError = defaultCountryId === 'CA' && validateTermsAndConditions ? !this.state.termsAndConditionsChecked : false;
        this.setState({ showTermsAndConditionsRequiredError });

        // If any data in either group is invalid, return false
        const checkoutValid = validAddress && validCustomerDetails && validATL && !showTermsAndConditionsRequiredError;

        if (!checkoutValid) {

            if (!validAddress) {
                this.addErrorMessage({ message: 'Invalid address, please refresh the page and try again' });
                console.log('Invalid address');
            }
            if (!validCustomerDetails) {
                this.addErrorMessage({ message: 'Invalid customer detail, please refresh the page and try again' });
                console.log('Invalid customer details');
            }
            if (!validATL) {
                this.addErrorMessage({ message: 'Invalid ATL, please refresh the page and try again' });
                console.log('Invalid ATL');
            }
            if (showTermsAndConditionsRequiredError) {
                this.addErrorMessage({ message: 'Terms and conditions not accepted, please check and try again' });
                console.log('T&C not checked');
            }

            this.scrollToFirstError();
        }
        return checkoutValid;
    };

    /**
     * Submit the checkout to the server for processing
     *
     * @returns {null}
     */
    submitCheckout = async (paymentMethod, component) => {
        this.setState({ payNowEnabled: false });
        const { storeCode, defaultCountryId, defaultSuccessPageUrl } = this.props.checkoutConfig;
        const quoteId = this.props.checkoutConfig.quoteId ? this.props.checkoutConfig.quoteId : this.props.checkoutConfig.quoteData.entity_id;

        this.clearErrorMessages();
        await this.increasePendingRequestsCountAndBlockUI();

        try {
            const billingAddress = processBillingAddressForCheckout(this.state.customer, this.state.address, defaultCountryId);
            const submitted = await submitCheckout(storeCode, quoteId, this.state.customer.email.value, paymentMethod, billingAddress, this.state.isLoggedIn);

            if (submitted.status === 200) {
                //send gta4 purchase event
                //window.gta4App = getGoogleAnalytics4();
                const gta4App = window.gta4App; // || {};

                if (gta4App) {
                    const totalsData = this.state.checkoutConfig.totalsData;
                    gta4App.pushGTMPurchase({
                        event: 'begin_purchase',
                        transactionID: this.state.checkoutConfig.quoteData.reserved_order_id,
                        currencyCode: totalsData.quote_currency_code || "",
                        totalPrice: totalsData.grand_total || "",
                        coupon: totalsData.coupon_code || "",
                        itemList: this.getItemDataForGta4(),
                    });
                }

                window.location.replace(defaultSuccessPageUrl);
            }
        } catch (error) {
            await this.decreasePendingRequestsCountAndUnblockUI();
            if (error && error.response && error.response.data) {
                console.log("app error", error.response.data.message);
                let localError = error;
                this.addErrorMessage({
                    message: error.response.data.message
                });
            }
            if (component !== null) {
                component.unmount();
                this.setState({
                    payNowEnabled: true
                })
            }
            //refresh payment section if server side error occurs dor cc cards
            if (paymentMethod.method === 'aligent_adyen' || paymentMethod.method === 'adyen_cc')
                this.setState({ payNowEnabled: true })
            this.paymentComponentRef.current.updateAdyenForm();
        }
    };

    /**
     * Tell Magento that the user is paying with PayPal
     *
     * @param {boolean} placeOrder Indicate if the order should be placed directly. If false, we're just telling Magento that
     *                             the user is about to pay with PayPal
     *
     * @returns {null}
     */
    submitPaypalCheckout = async (placeOrder = false) => {
        this.setState({
            payNowEnabled: false
        })

        const valid = await this.isCheckoutValid();
        if (!valid) {
            this.setState({
                payNowEnabled: true
            })
            return false;
        }

        const { storeCode, quoteId } = this.props.checkoutConfig;
        const paymentMethod = {
            additional_data: null,
            method: 'paypal_express',
            po_number: null
        };

        await this.increasePendingRequestsCount();
        await setPaymentInformation(storeCode, quoteId, this.state.customer.email.value, paymentMethod, this.state.isLoggedIn);
        await this.decreasePendingRequestsCount();

        if (placeOrder) {
            await this.increasePendingRequestsCount();
            // Getting to this point, the user is completing checkout, so they hopefully won't be reloading the page again!
            Storage.removeItem(`fn-checkout-app-state-${this.props.checkoutConfig.quoteId}`);
            Storage.removeItem(`fn-checkout-delivery-state-${this.props.checkoutConfig.quoteId}`);
            Storage.removeItem('find-in-store__search-location');
            Storage.removeItem('find-in-store__pickup-location');
            // This controller action isn't set up for AJAX, so we need to redirect the user to the page in order to execute
            window.location.replace('/paypal/express/placeOrder?_=' + Date.now());
        }
    };

    submitZippayCheckout = async () => {
        const { storeCode, quoteId } = this.props.checkoutConfig;

        const paymentMethod = {
            additional_data: null,
            method: 'zippayment',
            po_number: null
        };

        await this.increasePendingRequestsCount();
        const paymentSet = await setPaymentInformation(storeCode, quoteId, this.state.customer.email.value, paymentMethod, this.state.isLoggedIn);
        await this.decreasePendingRequestsCount();

        return paymentSet;
    };

    submitLaybuyCheckout = async () => {
        const { storeCode, quoteId } = this.props.checkoutConfig;

        const paymentMethod = {
            additional_data: null,
            method: 'laybuy_payment',
            po_number: null
        };

        await this.increasePendingRequestsCount();
        const paymentSet = await setPaymentInformation(storeCode, quoteId, this.state.customer.email.value, paymentMethod, this.state.isLoggedIn);
        await this.decreasePendingRequestsCount();

        return paymentSet;
    };

    submitAfterpayCheckout = async (paymentMethod) => {
        await this.increasePendingRequestsCount();
        const paymentSet = await setPaymentInformation(storeCode, quoteId, this.state.customer.email.value, paymentMethod, this.state.isLoggedIn);
        await this.decreasePendingRequestsCount();
    };

    //#gta4
    setProductCategory = function (categoryIds) {
        // retrieving from window directly
        // or better to pass as prop instead?
        const storeCategoriesJson = window.storeCategories;
        const storeCategories = [];
        // turn JSON into array
        for (const category in storeCategoriesJson) {
            storeCategories.push(category);
        }
        // only return DL categories that exist in store
        return storeCategories
            .filter(category => categoryIds.includes(category))
            .toString();
    };

    //#gta4
    getItemCategory = function(productID) {
        let output = '';
        if (window.productList && window.productList.length>0) {
            for (let i=0, len=window.productList.length; i<len; i++) {
                if (window.productList[i].productID==productID) {
                    output = window.productList[i].productCategory;
                    break;
                }
            }
        }
        return output;
    }

    //#gta4
    getItemDataForGta4 = function () {
        let output = [];
        for (let id in this.props.checkoutConfig.quoteItemData) {
            let item = this.props.checkoutConfig.quoteItemData[id];
            const { name,
                sku: sku,
                base_price_incl_tax: base_price_incl_tax, // same for regular and sales price
                qty: qty,
            } = item;
            const itemColour = getProductOption(item, 'fashion_colour');
            const itemSize = getProductOption(item, 'size');
            const formattedPrice = parseFloat(base_price_incl_tax).toFixed(2);
            const categoryIds = item.product.category_ids;

            output.push({
                productID: item.product_id,
                name: item.name,
                id: item.sku,
                price: formattedPrice,
                brand: 'Forever New',
                category: this.getItemCategory(item.sku),
                variant: `${itemColour ? itemColour.value : 'No-Colour'}_${itemSize ? itemSize.value : ''}`,
                quantity: qty,
                color_level_id: item.color_level_id ? item.color_level_id : '',
                style_level_id: item.product.sku
            });
        }
        return output;
    };

    /**
     * Activate another section of the application
     *
     * @param {int} nextSection The ID of the next section. This ID will correspond with a constant set in HeaderTabs
     *
     * @returns {null}
     */
    activateSection = async (nextSection) => {
        this.setState({
            activeSection: nextSection
        })
        let canChangeToSection;

        //gta4
        if (!window.reactData) {
            window.reactData = {
                version: "0.17ra",
                log: [],
                state: {},
            };
        }
        window.reactData.log.push("App.jsx activateSection() called with " + nextSection);
        window.reactData.state.getItemDataForGta4 = this.getItemDataForGta4();

        const gta4App = window.gta4App || {};

        switch (nextSection) {
            case HeaderTabs.CONST__PAYMENT_LINK:
                //#gta4
                if (gta4App) {
                    gta4App.pushGTMAddPaymentInfoStep({
                        //currencyCode: this.props.totalsData.base_currency_code || "",
                        totalPrice: "",
                        coupon: "",
                        itemList: this.getItemDataForGta4(),
                    });
                }
                canChangeToSection = this.state.checkoutConfig.paymentMethods.length > 0 && await this.isCheckoutValid(false);
                if (this.paymentComponentRef.current.paymentSection.current) {
                    this.paymentComponentRef.current.paymentSection.current.scrollIntoView({
                        behavior: 'smooth',
                        block: "start"
                    })
                }
                break;
            case HeaderTabs.CONST__DELIVERY_LINK:
                //#gta4
                if (gta4App) {
                    gta4App.pushGTMAddShippingInfoStep({
                        //currencyCode: this.props.totalsData.base_currency_code || "",
                        totalPrice: "",
                        coupon: "",
                        itemList: this.getItemDataForGta4(),
                    });
                }
                canChangeToSection = this.state.isLoggedIn || this.state.isGuest;
                if (this.deliveryComponentRef.current.deliverySection.current) {
                    this.deliveryComponentRef.current.deliverySection.current.scrollIntoView({
                        behavior: 'smooth',
                        block: "start"
                    })
                }
                break;

            case HeaderTabs.CONST__BAG_LINK:
                //#gta4
                if (gta4App) {
                    gta4App.pushGTMStartCheckout({
                        //currencyCode: this.props.totalsData.base_currency_code || "",
                        totalPrice: "",
                        coupon: "",
                        itemList: this.getItemDataForGta4(),
                    });
                }
                canChangeToSection = true;
                if (this.itemComponentRef.current.itemSummaryContainer.current) {
                    this.itemComponentRef.current.itemSummaryContainer.current.scrollIntoView({
                        behavior: 'smooth',
                        block: "start"
                    })
                }
                break;
            default:
                canChangeToSection = true;
        }

        // If the user can't yet change to the clicked section, just return
        if (!canChangeToSection) {
            return;
        }

        this.setState({
            reviewItems: false // The review items message will no longer be needed when the user "continues" from the item summary section
        }, () => {
            //window.scrollTo(0, 0);
        });
    };

    handleScrollActivateSection = async (nextSection) => {
        this.setState({
            activeSection: nextSection
        })
        let canChangeToSection;

        switch (nextSection) {
            case HeaderTabs.CONST__PAYMENT_LINK:
                canChangeToSection = this.state.checkoutConfig.paymentMethods.length > 0;
                break;
            case HeaderTabs.CONST__DELIVERY_LINK:
                canChangeToSection = this.state.isLoggedIn || this.state.isGuest;
                break;

            case HeaderTabs.CONST__BAG_LINK:
                canChangeToSection = true;
                break;
            default:
                canChangeToSection = true;
        }

        // If the user can't yet change to the clicked section, just return
        if (!canChangeToSection) {
            return;
        }

        this.setState({
            reviewItems: false // The review items message will no longer be needed when the user "continues" from the item summary section
        }, () => {
            //window.scrollTo(0, 0);
        });
    };

    /**
     *
     * @returns {Promise<any>}
     */
    setAdyenIsLoading = (isLoading) => {
        return new Promise((res) => {
            this.setState({
                adyenIsLoading: isLoading,
                // payNowEnabled: !isLoading
            }, () => {
                res();
            });
        });
    };

    setPendingRequestsBlockUI = () => {
        this.setState({
            // MS-2752 - I have removed ...this.state, - To avoid missing state data while updating state simultaneously.
            pendingRequestsBlockUI: true
        });
    }

    unsetPendingRequestsBlockUI = () => {
        this.setState({
            ...this.state,
            pendingRequestsBlockUI: false
        });
    }

    /**
     * Sets cookie to reload minicart
     *
     * @returns {null}
     */
    setReloadMinicartCookie() {
        const { cookies } = this.props;
        cookies.set('reloadMinicart', '1', { path: '/' });
    };

    /**
     * Checks whether a postcode, state, suburb combination is valid and updates error messages
     *
     * @returns {Promise<void>}
     */
    checkPostcodeCombination = async () => {
        const { postcode, region, suburb } = this.state.address;
        const isValid = await this.postcodeCombinationIsValid(postcode.value, region.value, suburb.value);
        //MS-3978 pay now button disable if address fail from paypal
        if (isValid) {
            this.setState({
                payNowEnabled: true
            })
            return;
        }

        this.setState({
            address: {
                ...this.state.address,
                suburb: {
                    ...this.state.address.suburb,
                    valid: false,
                    errorMessage: 'Sorry, that data entered for suburb, postcode and state does not match. Please review and update address.'
                }
            },
            payNowEnabled: false
        });
    };

    /**
     * Determines whether a given combination of postcode, region and suburb match an entry returned from the API
     *
     * @param {String}      postcode
     * @param {String}      region
     * @param {String}      suburb
     * @retuns {boolean}    Whether the combination is valid
     */
    postcodeCombinationIsValid = async (postcode, region, suburb) => {
        const results = await this.searchSuburb(suburb);
        if (results.status !== 200 || !results.data) {
            return false;
        }

        let isValid = false;
        results.data.forEach((result) => {
            let stateField = result.state;
            const postcodesMatch = result.postcode === postcode;
            const regionsMatch = stateField.toUpperCase() === region.toUpperCase();
            const suburbsMatch = result.suburb.includes(suburb);
            if (postcodesMatch && regionsMatch && suburbsMatch) {
                isValid = true;
            }
        });
        return isValid;
    };

    /**
     * Clears errors from the autocomplete suburb field
     *
     * @returns {null}
     */
    clearAutoCompleteSuburbErrors() {
        this.setState({
            address: {
                ...this.state.address,
                suburb: {
                    ...this.state.address.suburb,
                    valid: true,
                    errorMessage: '',
                }
            }
        });
    }

    /**
     * Clears the suburb data that was selected via suburb search
     *
     * @returns {null}
     */
    clearSelectedSuburb() {
        this.setState({
            address: {
                ...this.state.address,
                region: {
                    ...this.state.address.region,
                    value: ''
                },
                postcode: {
                    ...this.state.address.postcode,
                    value: ''
                }
            }
        });
    }

    /**
     * Handles searches triggered from suburb field changes
     *
     * @param {string}      query The suburb or postcode query to search for
     * @returns {Promise}   Search results
     */
    handleAutocompleteSuburbFieldChange = async (query) => {

        this.clearAutoCompleteSuburbErrors();
        this.clearSelectedSuburb();
        return await this.searchSuburb(query);

    };

    /**
     * Sends an ajax request to the postcode API
     *
     * @param {string}      query   The search term
     * @param {string}      countryId The default country code
     * @returns {Promise}   Search results
     */
    searchSuburb = async (query) => {
        await this.increasePendingRequestsCount();
        try {
            const result = await suburbLookup(query, this.state.checkoutConfig.defaultCountryId);
            await this.decreasePendingRequestsCount();
            return result;
        } catch (error) {
            // This catch clause is triggered when a pending request is cancelled for a new one
            await this.decreasePendingRequestsCount();
        }
    };

    /**
     * Transforms the suggested suburbs into the required format
     *
     * @returns {Array} of suburb suggestions
     */
    transformSuburbSuggestionResults = (result) => {
        if (!result) {
            // Request was cancelled and replaced by a new one
            return [{
                value: 'Searching...',
                selectable: false
            }];
        }

        if (result.data.length === 0) {
            // Show 'no-results' error
            this.setState({
                address: {
                    ...this.state.address,
                    suburb: {
                        ...this.state.address.suburb,
                        valid: false,
                        errorMessage: 'No results found. Please check that you have entered a valid suburb or postcode'
                    }
                }
            });
            return [];
        }

        return result.data.map((suggestion) => {
            return {
                ...suggestion,
                value: suggestion.s,
                selectable: true
            }
        })
    };

    /**
     * Handles clicks on suburb suggestions
     *
     * @param {Object} selectedItem The suggestion that was clicked
     * @returns {null}
     */
    handleSuburbSuggestionClick = async (selectedItem) => {
        this.setState({
            address: {
                ...this.state.address,
                suburb: {
                    ...this.state.address.suburb,
                    value: selectedItem.suburb,
                    valid: true
                },
                region: {
                    ...this.state.address.region,
                    value: selectedItem.state,
                    valid: true,
                    disabled: this.props.checkoutConfig.enable_custom_address_capture
                },
                postcode: {
                    ...this.state.address.postcode,
                    value: selectedItem.postcode,
                    valid: true,
                    disabled: this.props.checkoutConfig.enable_custom_address_capture
                }
            },
            payNowEnabled: true
        })
        this.increasePendingRequestsCount();
        await this.fetchShippingPaymentMethodsDebounced();
        this.validateCustomerDetails('address');
        this.validateStreet();
        this.paymentComponentRef.current.updateAdyenForm();
        this.validateManualAddressViaExperianDebounced();
        this.fetchShippingMethodsDebounced();
        this.decreasePendingRequestsCount();

    };

    render() {
        let checkoutHead = RegExp("Forever New").test(navigator.userAgent) || RegExp("ForeverNew").test(navigator.userAgent) ? 'checkout-header checkoutHead' : 'checkout-header ';
        return (
            <section className="react-checkout-app">
                <header className={checkoutHead}>
                    <HeaderTabs
                        activeSection={this.state.activeSection}
                        handleChangeActiveSection={this.activateSection}
                        selectedShippingMethod={this.state.selectedShippingMethod}
                    />
                    {this.state.errorMessages.length !== 0 && (
                        <div className="error-container-wrapper">
                            <ErrorContainer
                                errors={this.state.errorMessages}
                            />
                            <div onClick={this.clearErrorMessages} className="error-close-button"><i className="fa fa-times-circle"></i></div>
                        </div>
                    )}
                    {this.state.successMessages.length !== 0 && (
                        <div className="success-container-wrapper">
                            <SuccessContainer
                                success={this.state.successMessages}
                            />
                            <div onClick={() => this.clearSuccessMessages()} className="success-close-button"><i className="fa fa-times-circle"></i></div>
                        </div>
                    )}
                </header>
                {this.state.errorMessages.length !== 0 && (
                    <div className="error-container-wrapper error-container-wrapper-push">
                        <ErrorContainer
                            errors={this.state.errorMessages}
                        />
                    </div>
                )}
                {this.state.successMessages.length !== 0 && (
                    <div className="success-container-wrapper success-container-wrapper-push">
                        <SuccessContainer
                            success={this.state.successMessages}
                        />
                    </div>
                )}
                <StaticBlock
                    staticBlocks={this.props.staticBlocks}
                    identifier="checkout-banner"
                    className="checkout-banner"
                />
                <section className="checkout-sections">
                    {this.state.pendingRequests > 0 && (
                        <div className={`loading-container ${this.state.pendingRequestsBlockUI ? "blocking" : ""}`}>
                            <div className="loading-container-wrapper">
                                <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
                            </div>
                        </div>
                    )}
                    <ItemSummary
                        ref={this.itemComponentRef}
                        activeSection={this.state.activeSection}
                        handleActivateSection={this.activateSection}
                        handleScrollActivateSection={this.handleScrollActivateSection}
                        checkoutConfig={this.state.checkoutConfig}
                        updateTotals={this.updateTotals}
                        handleUpdateConfigurable={this.handleUpdateConfigurable}
                        handleRemoveItem={this.handleRemoveItemFromCart}
                        isLoggedIn={this.state.isLoggedIn}
                        isGuest={this.state.isGuest}
                        stockists={this.state.stockists}
                        selectedClickCollectStore={this.state.selectedClickCollectStore}
                        reviewItems={this.state.reviewItems}
                    />
                    <Delivery
                        ref={this.deliveryComponentRef}
                        checkoutConfig={this.state.checkoutConfig}
                        checkoutApp={this.state.checkoutApp}
                        activeSection={this.state.activeSection}
                        handleActivateSection={this.activateSection}
                        handleScrollActivateSection={this.handleScrollActivateSection}
                        isLoggedIn={this.state.isLoggedIn}
                        isGuest={this.state.isGuest}
                        handleUserIsGuest={this.handleUserIsGuest}
                        handleUserLoggedIn={this.handleUserLoggedIn}
                        handleGuestCheckout={this.handleGuestCheckout}
                        handleChangeCustomer={this.handleChangeCustomer}
                        handleOnBlur={this.handleOnBlur}
                        handleManualAddressChange={this.handleManualAddressChange}
                        handleSelectAutosuggest={this.handleSelectAutosuggest}
                        onSelectAddressBook={this.handleSelectAddressbook}
                        customer={this.state.customer}
                        address={this.state.address}
                        handleSearch={this.handleSearchAddress}
                        displayOneLineAddress={this.state.displayOneLineAddress}
                        allowFullAddressForm={this.state.allowFullAddressForm}
                        toggleFullForm={this.toggleFullForm}
                        shippingMethods={this.state.shippingMethods}
                        selectedShippingMethod={this.state.selectedShippingMethod}
                        selectedClickCollectStore={this.state.selectedClickCollectStore}
                        handleShippingMethodsChange={this.handleSelectShippingMethod}
                        stockists={this.state.stockists}
                        searchClickCollectStores={this.handleSearchClickCollectStores}
                        getSubSiteDirectory={this.getSubSiteDirectory}
                        handleSelectClickCollectStore={this.handleSelectClickCollectStore}
                        atlNotes={this.state.address.atl_instructions}
                        handleAuthorityToLeaveChange={this.handleAuthorityToLeaveChange}
                        handleAuthorityToLeaveNotesChange={this.handleAuthorityToLeaveNotesChange}
                        validateAddressDetails={this.validateAddressDetails}
                        validateCustomerDetails={this.validateCustomerDetails}
                        validateShippingMethod={this.validateShippingMethod}
                        validateClickCollectStoreSelected={this.validateClickCollect}
                        fetchShippingMethods={this.fetchShippingMethodsDebounced}
                        clearGlobalErrorMessages={this.clearErrorMessages}
                        handleChangeAutoCompleteInput={this.handleChangeAutoCompleteInput}
                        trackVisibleDeliveryTab={this.handleDeliveryTabChange}
                        increasePendingRequestsCount={this.increasePendingRequestsCount}
                        decreasePendingRequestsCount={this.decreasePendingRequestsCount}
                        scrollToFirstError={this.scrollToFirstError}
                        findInStore={{
                            selectedStore: this.state.findInStoreSelected,
                            location: this.state.findInStoreLocation
                        }}
                        errorSelectedClickCollectStore={this.state.errorSelectedClickCollectStore}
                        clearSelectedShippingMethod={this.clearSelectedShippingMethod}
                        removeClickAndCollectStore={this.handleRemoveAndClickCollectStore}
                        autoCompleteSuburb={{
                            show: this.state.showAutoCompleteSuburb,
                            handleSearch: this.handleAutocompleteSuburbFieldChange,
                            handleSuggestionClick: this.handleSuburbSuggestionClick,
                            transformCompleteRequest: this.transformSuburbSuggestionResults,
                        }}
                        checkisProductValid={this.checkisProductValid}
                        cart={this.state.checkoutConfig.quoteItemData}
                    />
                    <Payment
                        ref={this.paymentComponentRef}
                        checkoutConfig={this.state.checkoutConfig}
                        customer={this.state.customer}
                        payNowEnabled={this.state.payNowEnabled}
                        activeSection={this.state.activeSection}
                        cancelExpressCheckout={this.cancelExpressCheckout}
                        handleActivateSection={this.activateSection}
                        handleScrollActivateSection={this.handleScrollActivateSection}
                        handlePromoCode={this.handlePromoCode}
                        handleGiftExchangeCard={this.handleGiftExchangeCard}
                        handleRemoveGiftExchangeCard={this.handleRemoveGiftExchangeCard}
                        handlePayNowAction={this.submitCheckout}
                        handlePaypalAction={this.submitPaypalCheckout}
                        handleZippayAction={this.submitZippayCheckout}
                        handleLaybuyAction={this.submitLaybuyCheckout}
                        handleAfterpay={this.submitAfterpayCheckout}
                        checkIsValidCheckout={this.isCheckoutValid}
                        globalErrorMessages={this.state.errorMessages}
                        addGlobalErrorMessage={this.addErrorMessage}
                        globalSuccessMessages={this.state.successMessages}
                        addGlobalSuccessMessage={this.addSuccessMessage}
                        clearGlobalErrorMessages={this.clearErrorMessages}
                        clearGlobalSuccessMessages={this.clearSuccessMessages}
                        validateQuote={this.validateQuote}
                        address={this.state.address}
                        isLoggedIn={this.state.isLoggedIn}
                        isGuest={this.state.isGuest}
                        setAdyenIsLoading={this.setAdyenIsLoading}
                        adyenIsLoading={this.state.adyenIsLoading}
                        toggleTermsAndConditions={this.toggleTermsAndConditions}
                        termsAndConditionsChecked={this.state.termsAndConditionsChecked}
                        showTermsAndConditionsRequiredError={this.state.showTermsAndConditionsRequiredError}
                    />
                </section>
                <Gtm
                    quoteItemData={this.state.checkoutConfig.quoteItemData}
                    totalsData={this.state.checkoutConfig.totalsData}
                />
            </section>
        );
    }

}

App.propTypes = {
    checkoutConfig: PropTypes.object.isRequired,
    checkoutApp: PropTypes.object.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    staticBlocks: PropTypes.arrayOf(PropTypes.shape({
        identifier: PropTypes.string,
        content: PropTypes.string
    }))
};

export default withCookies(App);
